import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    // primary: {
    //   main: "#DA2027",
    // },
    // secondary: {
    //   main: "#19857b",
    // },
    error: {
      main: red.A400,
    },
  },
  // typography: {
  //   fontFamily: "SourceHanSansCN",
  // },
  typography: {
    fontFamily: ["SourceHanSansCN", "SourceHanSerifCN"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1920,
    },
  },
});

export default theme;
