import { FormControlLabel, Checkbox, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosInstance } from "../../utils/axiosInstance";
import { Button } from "@mui/base";
import { countries } from "../../data/coutries";

//phoneRegExp
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

//form schema
const schema = yup.object().shape({
  firstName: yup.string().required("请输入正确姓名"),
  secondName: yup.string().required("请输入正确姓名"),
  phoneNumber: yup
    .string()
    .required("请输入正确电话")
    .matches(phoneRegExp, "请输入正确电话"),
  email: yup.string().required("请输入正确邮箱").email("请输入正确邮箱"),
  accept: yup
    .boolean()
    .test("accept", "请确认条款", (val) => val)
    .default(false),
});

const RegisterForm = ({ uid }) => {
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setSubmitting(true);

    const data = {
      firstName: `${values.firstName}`,
      lastName: `${values.secondName}`,
      email: values.email,
      phone: `${values.phoneCode}${values.phoneNumber}`,
      campaign: "youtube",
      refStaff: `${uid}`,
    };

    try {
      const res = await axiosInstance.post("/customer-leads", { data });

      if (res) {
        reset();
        setMessage(true);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.log(error.message);
    }
  };

  return (
    <FormRoot
      onSubmit={handleSubmit(onSubmit)}
      onClick={() => {
        setMessage(false);
        clearErrors();
      }}
    >
      <div id="form">
        <Typography variant="h2">免费注册领取试听课</Typography>
        <MessageContainer>
          {message && <p>信息发送成功</p>}
          {submitting && <p>信息发送中</p>}
        </MessageContainer>
        <FormItem>
          <label>
            名 Frist name <span>*</span>
            {errors?.firstName && <span>{errors.firstName.message}</span>}
          </label>
          <input type="text" {...register("firstName")} />
        </FormItem>
        <FormItem>
          <label>
            姓 Surname <span>*</span>
            {errors?.secondName && <span>{errors.secondName.message}</span>}
          </label>
          <input type="text" {...register("secondName")} />
        </FormItem>
        <FormItem>
          <label>
            电话 Phone <span>*</span>
            {errors?.phoneNumber && <span>{errors.phoneNumber.message}</span>}
          </label>
          <PhoneItem>
            <select defaultValue="61" {...register("phoneCode")}>
              {countries.map((item, index) => (
                <option key={index} value={item.phoneCode}>
                  {item.code} +{item.phoneCode}
                </option>
              ))}
            </select>
            <input type="text" {...register("phoneNumber")} />
          </PhoneItem>
        </FormItem>
        <FormItem className="email">
          <label>
            邮箱 Email <span>*</span>
            {errors?.email && <span>{errors.email.message}</span>}
          </label>
          <input type="text" {...register("email")} />
        </FormItem>
        <AcceptItem>
          <p>{errors?.accept && <span>{errors.accept.message}</span>}</p>
          <div>
            <FormControlLabel
              sx={{ "&.MuiFormControlLabel-root": { marginRight: 0 } }}
              control={
                <Controller
                  name="accept"
                  control={control}
                  render={({ field }) => (
                    <CheckboxWrapper
                      {...field}
                      checked={field.value || false}
                      onChange={field.onChange}
                    />
                  )}
                />
              }
            />
            <span>同意接受来自公司的信息，其中可能包含营销材料</span>
          </div>
        </AcceptItem>
      </div>
      <StyledButton type="submit" disabled={submitting}>
        立即注册
      </StyledButton>
    </FormRoot>
  );
};

const FormRoot = styled("form")((props) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  "& *": {
    fontFamily: "SourceHanSerifCN !important",
  },
  "&>div": {
    padding: "20px 40px 10px",
    color: "#000000",
    "&>h2": {
      fontSize: 30,
      fontWeight: "bold",
      lineHeight: "50px",
      textAlign: "center",
    },
  },
  [props.theme.breakpoints.down(600)]: {
    "&>div": {
      padding: 20,
      "&>h2": {
        fontSize: 24,
        lineHeight: "30px",
      },
    },
  },
}));

const MessageContainer = styled("div")((props) => ({
  height: 20,
  // marginBottom: 10,
  "&>p": {
    fontSize: 15,
    padding: 0,
    margin: 0,
    color: "#FF0000",
    textAlign: "center",
  },
}));

const FormItem = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: 10,
  "&>label": {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "24px",
    // marginBottom: 5,
    "&>span": {
      paddingLeft: 10,
      fontSize: 12,
      fontWeight: 400,
      color: "#FF0000",
    },
    "&>span:first-of-type": {
      paddingLeft: 0,
    },
  },
  "&>input": {
    height: 40,
    background: "#F5F5F5",
    borderRadius: 10,
    border: "0px solid",
    padding: 5,
  },
  [props.theme.breakpoints.down(600)]: {
    "&.email": {
      paddingBottom: 0,
    },
    "&>input": {
      height: 35,
      padding: 5,
    },
  },
}));

const PhoneItem = styled("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "&>select": {
    height: 40,
    width: "35%",
    background: "#F5F5F5",
    borderRadius: 10,
    border: "0px solid",
    padding: 5,
  },
  "&>input": {
    height: 40,
    width: "55%",
    background: "#F5F5F5",
    borderRadius: 10,
    border: "0px solid",
    padding: 5,
  },
  [props.theme.breakpoints.down(600)]: {
    "&>select": {
      height: 35,
    },
    "&>input": {
      height: 35,
      padding: 5,
    },
  },
}));

const AcceptItem = styled("div")((props) => ({
  color: "#808080",
  "&>p": {
    height: 15,
    fontSize: 12,
    lineHeight: "12px",
    color: "#FF0000",
    margin: 0,
  },
  "&>div>span": {
    fontSize: 12,
  },
  [props.theme.breakpoints.down(600)]: {
    "&>div>span": {
      fontSize: 12,
    },
  },
}));

export const CheckboxWrapper = styled(Checkbox)((props) => ({
  height: 18,
  "&.Mui-input": {
    padding: "10px",
  },
  "&.Mui-checked": {
    color: "#0075FF",
  },
  "&>svg": {
    width: 15,
    color: "#808080"
  }
}));

const StyledButton = styled(Button)((props) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: 80,
  backgroundImage:
    "linear-gradient(to right, rgba(202,35,52,0.99608) 0%, rgb(136,36,101) 100%)",
  color: "#ffffff",
  border: "none",
  fontSize: 28,
  fontWeight: "bold",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.8,
  },
  [props.theme.breakpoints.down(600)]: {
    height: 50,
    fontSize: 20,
  },
}));

export default RegisterForm;
