export const countries = [
  {
    code: "AF",
    code3: "AFG",
    cnName: "阿富汗",
    enName: "Afghanistan",
    twName: "阿富汗",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "93",
    amountLimit: null,
    currency: "USD",
    thName: "อัฟกานิสถาน",
    nationality: "Afghan",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Afghanistan",
  },
  {
    code: "AL",
    code3: "ALB",
    cnName: "阿尔巴尼亚",
    enName: "Albania",
    twName: "阿爾巴尼亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "355",
    amountLimit: null,
    currency: "USD",
    thName: "แอลเบเนีย",
    nationality: "Albanian",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Albania",
  },
  {
    code: "DZ",
    code3: "DZA",
    cnName: "阿尔及利亚",
    enName: "Algeria",
    twName: "阿爾及利亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "213",
    amountLimit: null,
    currency: "USD",
    thName: "แอลจีเรีย",
    nationality: "Algerian",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Algeria",
  },
  {
    code: "AS",
    code3: "ASM",
    cnName: "美属萨摩亚",
    enName: "American Samoa",
    twName: "美屬薩摩亞",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: false,
    rating: "F",
    phoneCode: "1684",
    amountLimit: null,
    currency: "USD",
    thName: "อเมริกันซามัว",
    nationality: "American Samoan",
    tag: null,
    decision: "X",
    cnFirst: "M",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "American Samoa",
  },
  {
    code: "AD",
    code3: "AND",
    cnName: "安道尔",
    enName: "Andorra",
    twName: "安道爾",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "376",
    amountLimit: null,
    currency: "USD",
    thName: "อันดอร์รา",
    nationality: "Andorran",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Andorra",
  },
  {
    code: "AO",
    code3: "AGO",
    cnName: "安哥拉",
    enName: "Angola",
    twName: "安哥拉",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "244",
    amountLimit: null,
    currency: "USD",
    thName: "แองโกลา",
    nationality: "Angolan",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Angola",
  },
  {
    code: "AI",
    code3: "AIA",
    cnName: "安圭拉",
    enName: "Anguilla",
    twName: "英屬安圭拉",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1264",
    amountLimit: null,
    currency: "USD",
    thName: "แองกวิลลา",
    nationality: "Anguillan",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Anguilla",
  },
  {
    code: "AQ",
    code3: "ATA",
    cnName: "南极洲",
    enName: "Antarctica",
    twName: "南極洲",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "672",
    amountLimit: null,
    currency: "USD",
    thName: "ทวิปแอนตาร์กติกา",
    nationality: "Antarctic",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Antarctica",
  },
  {
    code: "AG",
    code3: "ATG",
    cnName: "安提瓜和巴布达",
    enName: "Antigua and Barbuda",
    twName: "安地卡及巴布達",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1268",
    amountLimit: null,
    currency: "USD",
    thName: "แอนติกาและบาร์บูดา",
    nationality: "Antiguan or Barbudan",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Antigua and Barbuda",
  },
  {
    code: "AR",
    code3: "ARG",
    cnName: "阿根廷",
    enName: "Argentina",
    twName: "阿根廷",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "A",
    phoneCode: "54",
    amountLimit: null,
    currency: "USD",
    thName: "อาร์เจนตินา",
    nationality: "Argentine",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Argentina",
  },
  {
    code: "AM",
    code3: "ARM",
    cnName: "亚美尼亚",
    enName: "Armenia",
    twName: "亞美尼亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "374",
    amountLimit: null,
    currency: "USD",
    thName: "อาร์เมเนีย",
    nationality: "Armenian",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Armenia",
  },
  {
    code: "AW",
    code3: "ABW",
    cnName: "阿鲁巴",
    enName: "Aruba",
    twName: "阿魯巴",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "297",
    amountLimit: null,
    currency: "USD",
    thName: "อารูบา",
    nationality: "Aruban",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Aruba",
  },
  {
    code: "AU",
    code3: "AUS",
    cnName: "澳大利亚",
    enName: "Australia",
    twName: "澳洲",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "A",
    phoneCode: "61",
    amountLimit: 0,
    currency: "AUD",
    thName: "ออสเตรเลีย",
    nationality: "Australian",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "A",
    companyId: 101,
    initial: "A",
    displayName: "Australia",
  },
  {
    code: "AT",
    code3: "AUT",
    cnName: "奥地利",
    enName: "Austria",
    twName: "奧地利",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "43",
    amountLimit: null,
    currency: "USD",
    thName: "ออสเตรีย",
    nationality: "Austrian",
    tag: "#EU#",
    decision: "",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Austria",
  },
  {
    code: "AZ",
    code3: "AZE",
    cnName: "阿塞拜疆",
    enName: "Azerbaijan",
    twName: "亞塞拜然",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "994",
    amountLimit: null,
    currency: "USD",
    thName: "อาเซอร์ไบจาน",
    nationality: "Azerbaijani, Azeri",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "A",
    companyId: 102,
    initial: "A",
    displayName: "Azerbaijan",
  },
  {
    code: "BS",
    code3: "BHS",
    cnName: "巴哈马",
    enName: "Bahamas",
    twName: "巴哈馬",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1242",
    amountLimit: null,
    currency: "USD",
    thName: "บาฮามาส",
    nationality: "Bahamian",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bahamas",
  },
  {
    code: "BH",
    code3: "BHR",
    cnName: "巴林",
    enName: "Bahrain",
    twName: "巴林",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "973",
    amountLimit: null,
    currency: "USD",
    thName: "บาห์เรน",
    nationality: "Bahraini",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bahrain",
  },
  {
    code: "BD",
    code3: "BGD",
    cnName: "孟加拉国",
    enName: "Bangladesh",
    twName: "孟加拉",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "880",
    amountLimit: null,
    currency: "USD",
    thName: "บังคลาเทศ",
    nationality: "Bangladeshi",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bangladesh",
  },
  {
    code: "BB",
    code3: "BRB",
    cnName: "巴巴多斯",
    enName: "Barbados",
    twName: "巴貝多",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1246",
    amountLimit: null,
    currency: "USD",
    thName: "บาร์เบโดส",
    nationality: "Barbadian",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Barbados",
  },
  {
    code: "BY",
    code3: "BLR",
    cnName: "白俄罗斯",
    enName: "Belarus",
    twName: "白俄羅斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "375",
    amountLimit: null,
    currency: "USD",
    thName: "เบลารุส",
    nationality: "Belarusian",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Belarus",
  },
  {
    code: "BE",
    code3: "BEL",
    cnName: "比利时",
    enName: "Belgium",
    twName: "比利時",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "32",
    amountLimit: null,
    currency: "USD",
    thName: "เบลเยียม",
    nationality: "Belgian",
    tag: "#EU#",
    decision: "",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Belgium",
  },
  {
    code: "BZ",
    code3: "BLZ",
    cnName: "伯利兹",
    enName: "Belize",
    twName: "貝里斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "501",
    amountLimit: null,
    currency: "USD",
    thName: "เบลีซ",
    nationality: "Belizean",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Belize",
  },
  {
    code: "BJ",
    code3: "BEN",
    cnName: "贝宁",
    enName: "Benin",
    twName: "貝南",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "229",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศเบนิน",
    nationality: "Beninese, Beninois",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Benin",
  },
  {
    code: "BM",
    code3: "BMU",
    cnName: "百慕大",
    enName: "Bermuda",
    twName: "百慕達",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1441",
    amountLimit: null,
    currency: "USD",
    thName: "เบอร์มิวดา",
    nationality: "Bermudian, Bermudan",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bermuda",
  },
  {
    code: "BT",
    code3: "BTN",
    cnName: "不丹",
    enName: "Bhutan",
    twName: "不丹",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "975",
    amountLimit: null,
    currency: "USD",
    thName: "ภูฏาน",
    nationality: "Bhutanese",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bhutan",
  },
  {
    code: "BO",
    code3: "BOL",
    cnName: "玻利维亚",
    enName: "Bolivia",
    twName: "玻利維亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "591",
    amountLimit: null,
    currency: "USD",
    thName: "โบลิเวีย",
    nationality: "Bolivian",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bolivia",
  },
  {
    code: "BQ",
    code3: "BES",
    cnName: "加勒比荷兰",
    enName: "Bonaire, Sint Eustatius and Saba",
    twName: "荷蘭加勒比區",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "599",
    amountLimit: null,
    currency: "USD",
    thName: "โบแนเรอ, ซินต์เอิสตาซียึส และซาบ",
    nationality: "Bonaire",
    tag: null,
    decision: "R",
    cnFirst: "J",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "BA",
    code3: "BIH",
    cnName: "波黑",
    enName: "Bosnia and Herzegovina",
    twName: "波赫",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "387",
    amountLimit: null,
    currency: "USD",
    thName: "บอสเนียและเฮอร์เซโก",
    nationality: "Bosnian or Herzegovinian",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bosnia and Herzegovina",
  },
  {
    code: "BW",
    code3: "BWA",
    cnName: "博茨瓦纳",
    enName: "Botswana",
    twName: "波札那",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "267",
    amountLimit: null,
    currency: "USD",
    thName: "บอตสวานา",
    nationality: "Motswana, Botswanan",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Botswana",
  },
  {
    code: "BV",
    code3: "BVT",
    cnName: "布韦岛",
    enName: "Bouvet Island",
    twName: "布威島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "55",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะบูเวต",
    nationality: "Bouvet Islander",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bouvet Island",
  },
  {
    code: "BR",
    code3: "BRA",
    cnName: "巴西",
    enName: "Brazil",
    twName: "巴西",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "55",
    amountLimit: null,
    currency: "USD",
    thName: "บราซิล",
    nationality: "Brazilian",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Brazil",
  },
  {
    code: "IO",
    code3: "IOT",
    cnName: "英属印度洋领地",
    enName: "British Indian Ocean Territory",
    twName: "英屬印度洋地區",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "246",
    amountLimit: null,
    currency: "USD",
    thName: "เขตมหาสมุทรบริติชอินเดีย",
    nationality: "BIOT",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "British Indian Ocean Territory",
  },
  {
    code: "VG",
    code3: "VGB",
    cnName: "英属维尔京群岛",
    enName: "British Virgin Islands",
    twName: "英屬維京群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "1284",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะบริติชเวอร์จิน",
    nationality: "British Virgin Islander",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "British Virgin Islands",
  },
  {
    code: "BN",
    code3: "BRN",
    cnName: "文莱",
    enName: "Brunei",
    twName: "汶萊",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "673",
    amountLimit: null,
    currency: "USD",
    thName: "บรูไน",
    nationality: "Bruneian",
    tag: null,
    decision: "R",
    cnFirst: "W",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Brunei",
  },
  {
    code: "BG",
    code3: "BGR",
    cnName: "保加利亚",
    enName: "Bulgaria",
    twName: "保加利亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "359",
    amountLimit: null,
    currency: "USD",
    thName: "บัลแกเรีย",
    nationality: "Bulgarian",
    tag: "#EU#",
    decision: "",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Bulgaria",
  },
  {
    code: "BF",
    code3: "BFA",
    cnName: "布基纳法索",
    enName: "Burkina Faso",
    twName: "布吉納法索",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "226",
    amountLimit: null,
    currency: "USD",
    thName: "บูร์กินาฟาโซ",
    nationality: "Burkinabé",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Burkina Faso",
  },
  {
    code: "BI",
    code3: "BDI",
    cnName: "布隆迪",
    enName: "Burundi",
    twName: "蒲隆地",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "257",
    amountLimit: null,
    currency: "USD",
    thName: "บุรุนดี",
    nationality: "Burundian",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "B",
    companyId: 102,
    initial: "B",
    displayName: "Burundi",
  },
  {
    code: "KH",
    code3: "KHM",
    cnName: "柬埔寨",
    enName: "Cambodia",
    twName: "柬埔寨",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "855",
    amountLimit: null,
    currency: "KHR",
    thName: "กัมพูชา",
    nationality: "Cambodian",
    tag: null,
    decision: "R",
    cnFirst: "J",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cambodia",
  },
  {
    code: "CM",
    code3: "CMR",
    cnName: "喀麦隆",
    enName: "Cameroon",
    twName: "喀麥隆",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "237",
    amountLimit: null,
    currency: "USD",
    thName: "แคเมอรูน",
    nationality: "Cameroonian",
    tag: null,
    decision: "R",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cameroon",
  },
  {
    code: "CA",
    code3: "CAN",
    cnName: "加拿大",
    enName: "Canada",
    twName: "加拿大",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "1",
    amountLimit: null,
    currency: "USD",
    thName: "แคนาดา",
    nationality: "Canadian",
    tag: null,
    decision: "U",
    cnFirst: "J",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Canada",
  },
  {
    code: "CV",
    code3: "CPV",
    cnName: "佛得角",
    enName: "Cape Verde",
    twName: "維德角",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "238",
    amountLimit: null,
    currency: "USD",
    thName: "เคปเวิร์ด",
    nationality: "Cabo Verdean",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cape Verde",
  },
  {
    code: "KY",
    code3: "CYM",
    cnName: "开曼群岛",
    enName: "Cayman Islands",
    twName: "開曼群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1345",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะเคย์เเมน",
    nationality: "Caymanian",
    tag: null,
    decision: "R",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cayman Islands",
  },
  {
    code: "CF",
    code3: "CAF",
    cnName: "中非",
    enName: "Central African Republic",
    twName: "中非",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "236",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐแอฟริกากลาง",
    nationality: "Central African",
    tag: null,
    decision: "",
    cnFirst: "Z",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Central African Republic",
  },
  {
    code: "TD",
    code3: "TCD",
    cnName: "乍得",
    enName: "Chad",
    twName: "查德",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "235",
    amountLimit: null,
    currency: "USD",
    thName: "ชาด",
    nationality: "Chadian",
    tag: null,
    decision: "R",
    cnFirst: "Z",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Chad",
  },
  {
    code: "CL",
    code3: "CHL",
    cnName: "智利",
    enName: "Chile",
    twName: "智利",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "56",
    amountLimit: null,
    currency: "USD",
    thName: "ชิลี",
    nationality: "Chilean",
    tag: null,
    decision: "R",
    cnFirst: "Z",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Chile",
  },
  {
    code: "CN",
    code3: "CHN",
    cnName: "中国",
    enName: "China",
    twName: "中國",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: false,
    rating: "B",
    phoneCode: "86",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศจีน",
    nationality: "Chinese",
    tag: null,
    decision: "X",
    cnFirst: "Z",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "China",
  },
  {
    code: "CX",
    code3: "CXR",
    cnName: "圣诞岛",
    enName: "Christmas Island",
    twName: "聖誕島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "61",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะคริสต์มาส",
    nationality: "Christmas Islander",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Christmas Island",
  },
  {
    code: "CC",
    code3: "CCK",
    cnName: "科科斯群岛",
    enName: "Cocos (Keeling) Islands",
    twName: "可可斯群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "61",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะโคโคส (คีลิง)",
    nationality: "Cocos Islander",
    tag: null,
    decision: "R",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cocos (Keeling) Islands",
  },
  {
    code: "CO",
    code3: "COL",
    cnName: "哥伦比亚",
    enName: "Colombia",
    twName: "哥倫比亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "57",
    amountLimit: null,
    currency: "USD",
    thName: "โคลอมเบีย",
    nationality: "Colombian",
    tag: null,
    decision: "",
    cnFirst: "G",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Colombia",
  },
  {
    code: "KM",
    code3: "COM",
    cnName: "科摩罗",
    enName: "Comoros",
    twName: "葛摩",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "269",
    amountLimit: null,
    currency: "USD",
    thName: "คอโมโรส",
    nationality: "Comorian",
    tag: null,
    decision: "R",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Comoros",
  },
  {
    code: "CD",
    code3: "COD",
    cnName: "刚果（金）",
    enName: "Congo, Democratic Republic of",
    twName: "民主剛果",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "243",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐประชาธิปไตยคองโก",
    nationality: "Congolese (Democratic Republic of)",
    tag: null,
    decision: "",
    cnFirst: "G",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Congo, Democratic Republic of",
  },
  {
    code: "CG",
    code3: "COG",
    cnName: "刚果（布）",
    enName: "Congo, Republic of",
    twName: "剛果",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "242",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐคองโก",
    nationality: "Congolese (Republic of)",
    tag: null,
    decision: "",
    cnFirst: "G",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Congo, Republic of",
  },
  {
    code: "CK",
    code3: "COK",
    cnName: "库克群岛",
    enName: "Cook Islands",
    twName: "庫克群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "682",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะคุก",
    nationality: "Cook Islander",
    tag: null,
    decision: "R",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cook Islands",
  },
  {
    code: "CR",
    code3: "CRI",
    cnName: "哥斯达黎加",
    enName: "Costa Rica",
    twName: "哥斯大黎加",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "506",
    amountLimit: null,
    currency: "USD",
    thName: "คอสตาริกา",
    nationality: "Costa Rican",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Costa Rica",
  },
  {
    code: "CI",
    code3: "CIV",
    cnName: "科特迪瓦",
    enName: "Cote d’Ivoire",
    twName: "象牙海岸",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "225",
    amountLimit: null,
    currency: "USD",
    thName: "โกตดิวัว",
    nationality: "Ivorian",
    tag: null,
    decision: "",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cote d’Ivoire",
  },
  {
    code: "HR",
    code3: "HRV",
    cnName: "克罗地亚",
    enName: "Croatia",
    twName: "克羅埃西亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "385",
    amountLimit: null,
    currency: "USD",
    thName: "โครเอเชีย",
    nationality: "Croatian",
    tag: "#EU#",
    decision: "",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Croatia",
  },
  {
    code: "CU",
    code3: "CUB",
    cnName: "古巴",
    enName: "Cuba",
    twName: "古巴",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "53",
    amountLimit: null,
    currency: "USD",
    thName: "คิวบา",
    nationality: "Cuban",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cuba",
  },
  {
    code: "CW",
    code3: "CUW",
    cnName: "库拉索",
    enName: "Curaao",
    twName: "古拉索",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "5999",
    amountLimit: null,
    currency: "USD",
    thName: "กือราเซา",
    nationality: "Curaçaoan",
    tag: null,
    decision: "R",
    cnFirst: "K",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Curaao",
  },
  {
    code: "CY",
    code3: "CYP",
    cnName: "塞浦路斯",
    enName: "Cyprus",
    twName: "塞普勒斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "357",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศไซปรัส",
    nationality: "Cypriot",
    tag: "#EU#",
    decision: "",
    cnFirst: "S",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Cyprus",
  },
  {
    code: "CZ",
    code3: "CZE",
    cnName: "捷克",
    enName: "Czech Republic",
    twName: "捷克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "420",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐเช็ก",
    nationality: "Czech",
    tag: "#EU#",
    decision: "",
    cnFirst: "J",
    enFirst: "C",
    companyId: 102,
    initial: "C",
    displayName: "Czech Republic",
  },
  {
    code: "DK",
    code3: "DNK",
    cnName: "丹麦",
    enName: "Denmark",
    twName: "丹麥",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "45",
    amountLimit: null,
    currency: "USD",
    thName: "เดนมาร์ก",
    nationality: "Danish",
    tag: "#EU#",
    decision: "",
    cnFirst: "D",
    enFirst: "D",
    companyId: 102,
    initial: "D",
    displayName: "Denmark",
  },
  {
    code: "DJ",
    code3: "DJI",
    cnName: "吉布提",
    enName: "Djibouti",
    twName: "吉布地",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "253",
    amountLimit: null,
    currency: "USD",
    thName: "จิบูตี",
    nationality: "Djiboutian",
    tag: null,
    decision: "R",
    cnFirst: "J",
    enFirst: "D",
    companyId: 102,
    initial: "D",
    displayName: "Djibouti",
  },
  {
    code: "DM",
    code3: "DMA",
    cnName: "多米尼克",
    enName: "Dominica",
    twName: "多米尼克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "1767",
    amountLimit: null,
    currency: "USD",
    thName: "โดมินิกา",
    nationality: "Dominican (Dominica)",
    tag: null,
    decision: "",
    cnFirst: "D",
    enFirst: "D",
    companyId: 102,
    initial: "D",
    displayName: "Dominica",
  },
  {
    code: "DO",
    code3: "DOM",
    cnName: "多米尼加",
    enName: "Dominican Republic",
    twName: "多明尼加",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "1809",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐโดมินิกัน",
    nationality: "Dominican (Dominican Republic)",
    tag: null,
    decision: "",
    cnFirst: "D",
    enFirst: "D",
    companyId: 102,
    initial: "D",
    displayName: "Dominican Republic",
  },
  {
    code: "EC",
    code3: "ECU",
    cnName: "厄瓜多尔",
    enName: "Ecuador",
    twName: "厄瓜多",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "593",
    amountLimit: null,
    currency: "USD",
    thName: "เอกวาดอร์",
    nationality: "Ecuadorian",
    tag: null,
    decision: "",
    cnFirst: "E",
    enFirst: "E",
    companyId: 102,
    initial: "E",
    displayName: "Ecuador",
  },
  {
    code: "EG",
    code3: "EGY",
    cnName: "埃及",
    enName: "Egypt",
    twName: "埃及",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "20",
    amountLimit: null,
    currency: "USD",
    thName: "อียิปต์",
    nationality: "Egyptian",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "E",
    companyId: 102,
    initial: "E",
    displayName: "Egypt",
  },
  {
    code: "SV",
    code3: "SLV",
    cnName: "萨尔瓦多",
    enName: "El Salvador",
    twName: "薩爾瓦多",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "503",
    amountLimit: null,
    currency: "USD",
    thName: "เอลซัลวาดอร์",
    nationality: "Salvadoran",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "E",
    companyId: 102,
    initial: "E",
    displayName: "El Salvador",
  },
  {
    code: "GQ",
    code3: "GNQ",
    cnName: "赤道几内亚",
    enName: "Equatorial Guinea",
    twName: "赤道幾內亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "240",
    amountLimit: null,
    currency: "USD",
    thName: "ทอเรียลกินี",
    nationality: "Equatoguinean",
    tag: null,
    decision: "",
    cnFirst: "C",
    enFirst: "E",
    companyId: 102,
    initial: "E",
    displayName: "Equatorial Guinea",
  },
  {
    code: "ER",
    code3: "ERI",
    cnName: "厄立特里亚",
    enName: "Eritrea",
    twName: "厄立垂亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "291",
    amountLimit: null,
    currency: "USD",
    thName: "เอริเทรี",
    nationality: "Eritrean",
    tag: null,
    decision: "",
    cnFirst: "E",
    enFirst: "E",
    companyId: 102,
    initial: "E",
    displayName: "Eritrea",
  },
  {
    code: "EE",
    code3: "EST",
    cnName: "爱沙尼亚",
    enName: "Estonia",
    twName: "愛沙尼亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "372",
    amountLimit: null,
    currency: "USD",
    thName: "เอสโตเนีย",
    nationality: "Estonian",
    tag: "#EU#",
    decision: "",
    cnFirst: "A",
    enFirst: "E",
    companyId: 102,
    initial: "E",
    displayName: "Estonia",
  },
  {
    code: "ET",
    code3: "ETH",
    cnName: "埃塞俄比亚",
    enName: "Ethiopia",
    twName: "衣索比亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "251",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐเอธิโอเปีย",
    nationality: "Ethiopian",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "E",
    companyId: 102,
    initial: "E",
    displayName: "Ethiopia",
  },
  {
    code: "FK",
    code3: "FLK",
    cnName: "马尔维纳斯群岛（福克兰）",
    enName: "Falkland Islands",
    twName: "福克蘭群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "500",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะฟอล์คแลนด์",
    nationality: "Falkland Islander",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "Falkland Islands",
  },
  {
    code: "FO",
    code3: "FRO",
    cnName: "法罗群岛",
    enName: "Faroe Islands",
    twName: "法羅群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "298",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะแฟโร",
    nationality: "Faroese",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "Faroe Islands",
  },
  {
    code: "FJ",
    code3: "FJI",
    cnName: "斐济群岛",
    enName: "Fiji",
    twName: "斐濟",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "679",
    amountLimit: null,
    currency: "USD",
    thName: "ฟิจิ",
    nationality: "Fijian",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "Fiji",
  },
  {
    code: "FI",
    code3: "FIN",
    cnName: "芬兰",
    enName: "Finland",
    twName: "芬蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "358",
    amountLimit: null,
    currency: "USD",
    thName: "ฟินแลนด์",
    nationality: "Finnish",
    tag: "#EU#",
    decision: "",
    cnFirst: "F",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "Finland",
  },
  {
    code: "FR",
    code3: "FRA",
    cnName: "法国",
    enName: "France",
    twName: "法國",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "33",
    amountLimit: null,
    currency: "USD",
    thName: "ฝรั่งเศส",
    nationality: "French",
    tag: "#EU#",
    decision: "",
    cnFirst: "F",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "France",
  },
  {
    code: "GF",
    code3: "GUF",
    cnName: "法属圭亚那",
    enName: "French Guiana",
    twName: "法屬蓋亞那",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "594",
    amountLimit: null,
    currency: "USD",
    thName: "เฟรนช์เกีย",
    nationality: "French",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "French Guiana",
  },
  {
    code: "PF",
    code3: "PYF",
    cnName: "法属波利尼西亚",
    enName: "French Polynesia",
    twName: "法屬玻里尼西亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "689",
    amountLimit: null,
    currency: "USD",
    thName: "เฟรนช์โปลินีเซีย",
    nationality: "French",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "French Polynesia",
  },
  {
    code: "TF",
    code3: "ATF",
    cnName: "法属南部领地",
    enName: "French Southern Territories",
    twName: "法屬南部屬地",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "262",
    amountLimit: null,
    currency: "USD",
    thName: "ดินแดนทางใต้ของฝรั่งเศส",
    nationality: "French",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "F",
    companyId: 102,
    initial: "F",
    displayName: "French Southern Territories",
  },
  {
    code: "GA",
    code3: "GAB",
    cnName: "加蓬",
    enName: "Gabon",
    twName: "加彭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "241",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศกาบอง",
    nationality: "Gabonese",
    tag: null,
    decision: "R",
    cnFirst: "J",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Gabon",
  },
  {
    code: "GM",
    code3: "GMB",
    cnName: "冈比亚",
    enName: "Gambia",
    twName: "甘比亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "220",
    amountLimit: null,
    currency: "USD",
    thName: "แกมเบีย",
    nationality: "Gambian",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Gambia",
  },
  {
    code: "GE",
    code3: "GEO",
    cnName: "格鲁吉亚",
    enName: "Georgia",
    twName: "喬治亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "995",
    amountLimit: null,
    currency: "USD",
    thName: "จอร์เจีย",
    nationality: "Georgian",
    tag: null,
    decision: "",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Georgia",
  },
  {
    code: "DE",
    code3: "DEU",
    cnName: "德国",
    enName: "Germany",
    twName: "德國",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "49",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศเยอรมัน",
    nationality: "German",
    tag: "#EU#",
    decision: "",
    cnFirst: "D",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Germany",
  },
  {
    code: "GH",
    code3: "GHA",
    cnName: "加纳",
    enName: "Ghana",
    twName: "迦納",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "233",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศกานา",
    nationality: "Ghanaian",
    tag: null,
    decision: "",
    cnFirst: "J",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Ghana",
  },
  {
    code: "GI",
    code3: "GIB",
    cnName: "直布罗陀",
    enName: "Gibraltar",
    twName: "直布羅陀",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "350",
    amountLimit: null,
    currency: "USD",
    thName: "ยิบรอลตา",
    nationality: "Gibraltar",
    tag: null,
    decision: "R",
    cnFirst: "Z",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Gibraltar",
  },
  {
    code: "GR",
    code3: "GRC",
    cnName: "希腊",
    enName: "Greece",
    twName: "希臘",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "30",
    amountLimit: null,
    currency: "USD",
    thName: "กรีก",
    nationality: "Greek, Hellenic",
    tag: "#EU#",
    decision: "",
    cnFirst: "X",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Greece",
  },
  {
    code: "GL",
    code3: "GRL",
    cnName: "格陵兰",
    enName: "Greenland",
    twName: "格陵蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "299",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะกรีนแลนด์",
    nationality: "Greenlandic",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Greenland",
  },
  {
    code: "GD",
    code3: "GRD",
    cnName: "格林纳达",
    enName: "Grenada",
    twName: "格瑞那達",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1473",
    amountLimit: null,
    currency: "USD",
    thName: "เกรเนดา",
    nationality: "Grenadian",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Grenada",
  },
  {
    code: "GP",
    code3: "GLP",
    cnName: "瓜德罗普",
    enName: "Guadeloupe",
    twName: "瓜德魯普島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "590",
    amountLimit: null,
    currency: "USD",
    thName: "กัวเดอลุป",
    nationality: "Guadeloupe",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Guadeloupe",
  },
  {
    code: "GU",
    code3: "GUM",
    cnName: "关岛",
    enName: "Guam",
    twName: "關島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1671",
    amountLimit: null,
    currency: "USD",
    thName: "กวม",
    nationality: "Guamanian",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Guam",
  },
  {
    code: "GT",
    code3: "GTM",
    cnName: "危地马拉",
    enName: "Guatemala",
    twName: "瓜地馬拉",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "502",
    amountLimit: null,
    currency: "USD",
    thName: "กัวเตมาลา",
    nationality: "Guatemalan",
    tag: null,
    decision: "R",
    cnFirst: "W",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Guatemala",
  },
  {
    code: "GG",
    code3: "GGY",
    cnName: "根西岛",
    enName: "Guernsey",
    twName: "根息島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "441481",
    amountLimit: null,
    currency: "USD",
    thName: "เสื้อไหมพรม",
    nationality: "British",
    tag: null,
    decision: "",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Guernsey",
  },
  {
    code: "GN",
    code3: "GIN",
    cnName: "几内亚",
    enName: "Guinea",
    twName: "幾內亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "224",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศกินี",
    nationality: "Guinean",
    tag: null,
    decision: "",
    cnFirst: "J",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Guinea",
  },
  {
    code: "GW",
    code3: "GNB",
    cnName: "几内亚比绍",
    enName: "Guinea-Bissau",
    twName: "幾內亞比索",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "245",
    amountLimit: null,
    currency: "USD",
    thName: "กินีบิสเซา",
    nationality: "Bissau-Guinean",
    tag: null,
    decision: "",
    cnFirst: "J",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Guinea-Bissau",
  },
  {
    code: "GY",
    code3: "GUY",
    cnName: "圭亚那",
    enName: "Guyana",
    twName: "蓋亞那",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "592",
    amountLimit: null,
    currency: "USD",
    thName: "กายอานา",
    nationality: "Guyanese",
    tag: null,
    decision: "R",
    cnFirst: "G",
    enFirst: "G",
    companyId: 102,
    initial: "G",
    displayName: "Guyana",
  },
  {
    code: "HT",
    code3: "HTI",
    cnName: "海地",
    enName: "Haiti",
    twName: "海地",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "509",
    amountLimit: null,
    currency: "USD",
    thName: "ไฮติ",
    nationality: "Haitian",
    tag: null,
    decision: "R",
    cnFirst: "H",
    enFirst: "H",
    companyId: 102,
    initial: "H",
    displayName: "Haiti",
  },
  {
    code: "HM",
    code3: "HMD",
    cnName: "赫德岛和麦克唐纳群岛",
    enName: "Heard Island and McDonald Islands",
    twName: "赫德及麥當勞群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "672",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์",
    nationality: "Heard Islander and McDonald Islander",
    tag: null,
    decision: "R",
    cnFirst: "H",
    enFirst: "H",
    companyId: 102,
    initial: "H",
    displayName: "Heard Island and McDonald Islands",
  },
  {
    code: "HN",
    code3: "HND",
    cnName: "洪都拉斯",
    enName: "Honduras",
    twName: "宏都拉斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "504",
    amountLimit: null,
    currency: "USD",
    thName: "ฮอนดูรัส",
    nationality: "Honduran",
    tag: null,
    decision: "R",
    cnFirst: "H",
    enFirst: "H",
    companyId: 102,
    initial: "H",
    displayName: "Honduras",
  },
  {
    code: "HK",
    code3: "HKG",
    cnName: "香港",
    enName: "Hong Kong SAR China",
    twName: "香港",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: false,
    rating: "F",
    phoneCode: "852",
    amountLimit: 0,
    currency: "USD",
    thName: "ฮ่องกง",
    nationality: "Chinese (Hong Kong)",
    tag: null,
    decision: "UQ",
    cnFirst: "X",
    enFirst: "H",
    companyId: 102,
    initial: "H",
    displayName: "Hong Kong SAR China",
  },
  {
    code: "HU",
    code3: "HUN",
    cnName: "匈牙利",
    enName: "Hungary",
    twName: "匈牙利",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "36",
    amountLimit: null,
    currency: "USD",
    thName: "ฮังการี",
    nationality: "Hungarian",
    tag: "#EU#",
    decision: "",
    cnFirst: "X",
    enFirst: "H",
    companyId: 102,
    initial: "H",
    displayName: "Hungary",
  },
  {
    code: "IS",
    code3: "ISL",
    cnName: "冰岛",
    enName: "Iceland",
    twName: "冰島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "354",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศไอซ์แลนด์",
    nationality: "Icelandic",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Iceland",
  },
  {
    code: "IN",
    code3: "IND",
    cnName: "印度",
    enName: "India",
    twName: "印度",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "A",
    phoneCode: "91",
    amountLimit: null,
    currency: "USD",
    thName: "อินเดีย",
    nationality: "Indian",
    tag: null,
    decision: "R",
    cnFirst: "Y",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "India",
  },
  {
    code: "ID",
    code3: "IDN",
    cnName: "印尼",
    enName: "Indonesia",
    twName: "印尼",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "62",
    amountLimit: null,
    currency: "IDR",
    thName: "อินโดนีเซีย",
    nationality: "Indonesian",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Indonesia",
  },
  {
    code: "IR",
    code3: "IRN",
    cnName: "伊朗",
    enName: "Iran",
    twName: "伊朗",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "98",
    amountLimit: null,
    currency: "USD",
    thName: "อิหร่าน",
    nationality: "Iranian",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Iran",
  },
  {
    code: "IQ",
    code3: "IRQ",
    cnName: "伊拉克",
    enName: "Iraq",
    twName: "伊拉克",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "964",
    amountLimit: null,
    currency: "USD",
    thName: "อิรัก",
    nationality: "Iraqi",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Iraq",
  },
  {
    code: "IE",
    code3: "IRL",
    cnName: "爱尔兰",
    enName: "Ireland",
    twName: "愛爾蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "353",
    amountLimit: null,
    currency: "USD",
    thName: "ไอร์แลนด์",
    nationality: "Irish",
    tag: "#EU#",
    decision: "",
    cnFirst: "A",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Ireland",
  },
  {
    code: "IM",
    code3: "IMN",
    cnName: "马恩岛",
    enName: "Isle of Man",
    twName: "曼島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "44",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะแมน",
    nationality: "Manx",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Isle of Man",
  },
  {
    code: "IL",
    code3: "ISR",
    cnName: "以色列",
    enName: "Israel",
    twName: "以色列",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "972",
    amountLimit: null,
    currency: "USD",
    thName: "อิสราเอล",
    nationality: "Israeli",
    tag: null,
    decision: "R",
    cnFirst: "Y",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Israel",
  },
  {
    code: "IT",
    code3: "ITA",
    cnName: "意大利",
    enName: "Italy",
    twName: "義大利",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "39",
    amountLimit: null,
    currency: "USD",
    thName: "อิตาลี",
    nationality: "Italian",
    tag: "#EU#",
    decision: "",
    cnFirst: "Y",
    enFirst: "I",
    companyId: 102,
    initial: "I",
    displayName: "Italy",
  },
  {
    code: "JM",
    code3: "JAM",
    cnName: "牙买加",
    enName: "Jamaica",
    twName: "牙買加",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1876",
    amountLimit: null,
    currency: "USD",
    thName: "จาเมกา",
    nationality: "Jamaican",
    tag: null,
    decision: "R",
    cnFirst: "Y",
    enFirst: "J",
    companyId: 102,
    initial: "J",
    displayName: "Jamaica",
  },
  {
    code: "JP",
    code3: "JPN",
    cnName: "日本",
    enName: "Japan",
    twName: "日本",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "81",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศญี่ปุ่น",
    nationality: "Japanese",
    tag: null,
    decision: "U",
    cnFirst: "R",
    enFirst: "J",
    companyId: 102,
    initial: "J",
    displayName: "Japan",
  },
  {
    code: "JE",
    code3: "JEY",
    cnName: "泽西岛",
    enName: "Jersey",
    twName: "澤西島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "44",
    amountLimit: null,
    currency: "USD",
    thName: "นิวเจอร์ซีย์",
    nationality: "British",
    tag: null,
    decision: "",
    cnFirst: "Z",
    enFirst: "J",
    companyId: 102,
    initial: "J",
    displayName: "Jersey",
  },
  {
    code: "JO",
    code3: "JOR",
    cnName: "约旦",
    enName: "Jordan",
    twName: "約旦",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "962",
    amountLimit: null,
    currency: "USD",
    thName: "จอร์แดน",
    nationality: "Jordanian",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "J",
    companyId: 102,
    initial: "J",
    displayName: "Jordan",
  },
  {
    code: "KZ",
    code3: "KAZ",
    cnName: "哈萨克斯坦",
    enName: "Kazakhstan",
    twName: "哈薩克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "7",
    amountLimit: null,
    currency: "USD",
    thName: "คาซัคสถาน",
    nationality: "Kazakhstani",
    tag: null,
    decision: "R",
    cnFirst: "H",
    enFirst: "K",
    companyId: 102,
    initial: "K",
    displayName: "Kazakhstan",
  },
  {
    code: "KE",
    code3: "KEN",
    cnName: "肯尼亚",
    enName: "Kenya",
    twName: "肯亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "254",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศเคนย่า",
    nationality: "Kenyan",
    tag: null,
    decision: "",
    cnFirst: "K",
    enFirst: "K",
    companyId: 102,
    initial: "K",
    displayName: "Kenya",
  },
  {
    code: "KI",
    code3: "KIR",
    cnName: "基里巴斯",
    enName: "Kiribati",
    twName: "吉里巴斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "686",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศคิริบาส",
    nationality: "I-Kiribati",
    tag: null,
    decision: "R",
    cnFirst: "J",
    enFirst: "K",
    companyId: 102,
    initial: "K",
    displayName: "Kiribati",
  },
  {
    code: "KW",
    code3: "KWT",
    cnName: "科威特",
    enName: "Kuwait",
    twName: "科威特",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "965",
    amountLimit: null,
    currency: "USD",
    thName: "คูเวต",
    nationality: "Kuwaiti",
    tag: null,
    decision: "",
    cnFirst: "K",
    enFirst: "K",
    companyId: 102,
    initial: "K",
    displayName: "Kuwait",
  },
  {
    code: "KG",
    code3: "KGZ",
    cnName: "吉尔吉斯斯坦",
    enName: "Kyrgyzstan",
    twName: "吉爾吉斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "996",
    amountLimit: null,
    currency: "USD",
    thName: "คีร์กีสถาน",
    nationality: "Kyrgyzstani",
    tag: null,
    decision: "R",
    cnFirst: "J",
    enFirst: "K",
    companyId: 102,
    initial: "K",
    displayName: "Kyrgyzstan",
  },
  {
    code: "LA",
    code3: "LAO",
    cnName: "老挝",
    enName: "Laos",
    twName: "寮國",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "856",
    amountLimit: null,
    currency: "LAK",
    thName: "ลาว",
    nationality: "Laotian",
    tag: null,
    decision: "R",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Laos",
  },
  {
    code: "LV",
    code3: "LVA",
    cnName: "拉脱维亚",
    enName: "Latvia",
    twName: "拉脫維亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "371",
    amountLimit: null,
    currency: "USD",
    thName: "ลัตเวีย",
    nationality: "Latvian",
    tag: "#EU#",
    decision: "",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Latvia",
  },
  {
    code: "LB",
    code3: "LBN",
    cnName: "黎巴嫩",
    enName: "Lebanon, Republic of",
    twName: "黎巴嫩",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "961",
    amountLimit: null,
    currency: "USD",
    thName: "เลบานอน",
    nationality: "Lebanese",
    tag: null,
    decision: "",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Lebanon, Republic of",
  },
  {
    code: "LS",
    code3: "LSO",
    cnName: "莱索托",
    enName: "Lesotho",
    twName: "賴索托",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "266",
    amountLimit: null,
    currency: "USD",
    thName: "เลโซโท",
    nationality: "Basotho",
    tag: null,
    decision: "R",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Lesotho",
  },
  {
    code: "LR",
    code3: "LBR",
    cnName: "利比里亚",
    enName: "Liberia, Republic of",
    twName: "賴比瑞亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "231",
    amountLimit: null,
    currency: "USD",
    thName: "ไลบีเรีย",
    nationality: "Liberian",
    tag: null,
    decision: "",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Liberia, Republic of",
  },
  {
    code: "LY",
    code3: "LBY",
    cnName: "利比亚",
    enName: "Libya, State of",
    twName: "利比亞",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "218",
    amountLimit: null,
    currency: "USD",
    thName: "รัฐลิเบีย",
    nationality: "Libyan",
    tag: null,
    decision: "",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Libya, State of",
  },
  {
    code: "LI",
    code3: "LIE",
    cnName: "列支敦士登",
    enName: "Liechtenstein",
    twName: "列支敦斯登",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "423",
    amountLimit: null,
    currency: "USD",
    thName: "ลิกเตนสไตน์",
    nationality: "Liechtenstein",
    tag: null,
    decision: "",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Liechtenstein",
  },
  {
    code: "LT",
    code3: "LTU",
    cnName: "立陶宛",
    enName: "Lithuania",
    twName: "立陶宛",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "370",
    amountLimit: null,
    currency: "USD",
    thName: "ลิทัวเนีย",
    nationality: "Lithuanian",
    tag: "#EU#",
    decision: "",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Lithuania",
  },
  {
    code: "LU",
    code3: "LUX",
    cnName: "卢森堡",
    enName: "Luxembourg",
    twName: "盧森堡",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "352",
    amountLimit: null,
    currency: "USD",
    thName: "ลักเซมเบิร์ก",
    nationality: "Luxembourger",
    tag: "#EU#",
    decision: "",
    cnFirst: "L",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "Luxembourg",
  },
  {
    code: "MO",
    code3: "MAC",
    cnName: "澳门",
    enName: "Macau SAR China",
    twName: "澳門",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "853",
    amountLimit: null,
    currency: "USD",
    thName: "เขตบริหารพิเศษมาเก๊าแห่งสาธารณรั",
    nationality: "Chinese (Macau)",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Macau SAR China",
  },
  {
    code: "MK",
    code3: "MKD",
    cnName: "马其顿",
    enName: "Macedonia",
    twName: "馬其頓",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "389",
    amountLimit: null,
    currency: "USD",
    thName: "มาซิโดเนีย",
    nationality: "Macedonian",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Macedonia",
  },
  {
    code: "MG",
    code3: "MDG",
    cnName: "马达加斯加",
    enName: "Madagascar",
    twName: "馬達加斯加",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "261",
    amountLimit: null,
    currency: "USD",
    thName: "มาดากัสการ์",
    nationality: "Malagasy",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Madagascar",
  },
  {
    code: "MW",
    code3: "MWI",
    cnName: "马拉维",
    enName: "Malawi",
    twName: "馬拉威",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "265",
    amountLimit: null,
    currency: "USD",
    thName: "มาลาวี",
    nationality: "Malawian",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Malawi",
  },
  {
    code: "MY",
    code3: "MYS",
    cnName: "马来西亚",
    enName: "Malaysia",
    twName: "馬來西亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "60",
    amountLimit: null,
    currency: "MYR",
    thName: "ประเทศมาเลเซีย",
    nationality: "Malaysian",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Malaysia",
  },
  {
    code: "MV",
    code3: "MDV",
    cnName: "马尔代夫",
    enName: "Maldives",
    twName: "馬爾地夫",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "960",
    amountLimit: null,
    currency: "USD",
    thName: "มัลดีฟส์",
    nationality: "Maldivian",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Maldives",
  },
  {
    code: "ML",
    code3: "MLI",
    cnName: "马里",
    enName: "Mali",
    twName: "馬利",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "223",
    amountLimit: null,
    currency: "USD",
    thName: "มาลี",
    nationality: "Malian",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Mali",
  },
  {
    code: "MT",
    code3: "MLT",
    cnName: "马耳他",
    enName: "Malta",
    twName: "馬耳他",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "356",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะมอลตา",
    nationality: "Maltese",
    tag: "#EU#",
    decision: "",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Malta",
  },
  {
    code: "MH",
    code3: "MHL",
    cnName: "马绍尔群岛",
    enName: "Marshall Islands",
    twName: "馬紹爾群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "692",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะมาร์แชลล์",
    nationality: "Marshallese",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Marshall Islands",
  },
  {
    code: "MQ",
    code3: "MTQ",
    cnName: "马提尼克",
    enName: "Martinique",
    twName: "法屬馬丁尼克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "596",
    amountLimit: null,
    currency: "USD",
    thName: "มาร์ตินีก",
    nationality: "Martiniquais",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Martinique",
  },
  {
    code: "MR",
    code3: "MRT",
    cnName: "毛里塔尼亚",
    enName: "Mauritania",
    twName: "茅利塔尼亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "222",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศมอริเตเนีย",
    nationality: "Mauritanian",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Mauritania",
  },
  {
    code: "MU",
    code3: "MUS",
    cnName: "毛里求斯",
    enName: "Mauritius",
    twName: "模里西斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "230",
    amountLimit: null,
    currency: "USD",
    thName: "มอริเชียส",
    nationality: "Mauritian",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Mauritius",
  },
  {
    code: "YT",
    code3: "MYT",
    cnName: "马约特",
    enName: "Mayotte",
    twName: "美亞特",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "262",
    amountLimit: null,
    currency: "USD",
    thName: "มายอต",
    nationality: "Mahoran",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Mayotte",
  },
  {
    code: "MX",
    code3: "MEX",
    cnName: "墨西哥",
    enName: "Mexico",
    twName: "墨西哥",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "52",
    amountLimit: null,
    currency: "USD",
    thName: "เม็กซิโก",
    nationality: "Mexican",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Mexico",
  },
  {
    code: "FM",
    code3: "FSM",
    cnName: "密克罗尼西亚联邦",
    enName: "Micronesia",
    twName: "密克羅尼西亞聯邦",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "691",
    amountLimit: null,
    currency: "USD",
    thName: "ไมโครนีเซีย",
    nationality: "Micronesian",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Micronesia",
  },
  {
    code: "MD",
    code3: "MDA",
    cnName: "摩尔多瓦",
    enName: "Moldova",
    twName: "摩爾多瓦",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "373",
    amountLimit: null,
    currency: "USD",
    thName: "มอลโดวา",
    nationality: "Moldovan",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Moldova",
  },
  {
    code: "MC",
    code3: "MCO",
    cnName: "摩纳哥",
    enName: "Monaco",
    twName: "摩納哥",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "377",
    amountLimit: null,
    currency: "USD",
    thName: "โมนาโก",
    nationality: "Monacan",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Monaco",
  },
  {
    code: "MN",
    code3: "MNG",
    cnName: "蒙古",
    enName: "Mongolia",
    twName: "蒙古",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "976",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศมองโกเลีย",
    nationality: "Mongolian",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Mongolia",
  },
  {
    code: "ME",
    code3: "MNE",
    cnName: "黑山",
    enName: "Montenegro",
    twName: "蒙特內哥羅",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "382",
    amountLimit: null,
    currency: "USD",
    thName: "มอนเตเนโก",
    nationality: "Montenegrin",
    tag: null,
    decision: "",
    cnFirst: "H",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Montenegro",
  },
  {
    code: "MS",
    code3: "MSR",
    cnName: "蒙塞拉特岛",
    enName: "Montserrat",
    twName: "蒙塞拉特島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "1664",
    amountLimit: null,
    currency: "USD",
    thName: "มอนต์เซอร์รัต",
    nationality: "Montserratian",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Montserrat",
  },
  {
    code: "MA",
    code3: "MAR",
    cnName: "摩洛哥",
    enName: "Morocco",
    twName: "摩洛哥",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "212",
    amountLimit: null,
    currency: "USD",
    thName: "โมร็อกโก",
    nationality: "Moroccan",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Morocco",
  },
  {
    code: "MZ",
    code3: "MOZ",
    cnName: "莫桑比克",
    enName: "Mozambique",
    twName: "莫三比克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "258",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศโมซัมบิก",
    nationality: "Mozambican",
    tag: null,
    decision: "R",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Mozambique",
  },
  {
    code: "MM",
    code3: "MMR",
    cnName: "缅甸",
    enName: "Myanmar [Burma]",
    twName: "緬甸",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "95",
    amountLimit: null,
    currency: "MMK",
    thName: "พม่า",
    nationality: "Burmese",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "M",
    companyId: 102,
    initial: "M",
    displayName: "Myanmar [Burma]",
  },
  {
    code: "NA",
    code3: "NAM",
    cnName: "纳米比亚",
    enName: "Namibia",
    twName: "奈米比亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "264",
    amountLimit: null,
    currency: "USD",
    thName: "นามิเบีย",
    nationality: "Namibian",
    tag: null,
    decision: "",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Namibia",
  },
  {
    code: "NR",
    code3: "NRU",
    cnName: "瑙鲁",
    enName: "Nauru",
    twName: "諾魯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "674",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศนาอูรู",
    nationality: "Nauruan",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Nauru",
  },
  {
    code: "NP",
    code3: "NPL",
    cnName: "尼泊尔",
    enName: "Nepal",
    twName: "尼泊爾",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "977",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศเนปาล",
    nationality: "Nepali",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Nepal",
  },
  {
    code: "NL",
    code3: "NLD",
    cnName: "荷兰",
    enName: "Netherlands",
    twName: "荷蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "31",
    amountLimit: null,
    currency: "USD",
    thName: "เนเธอร์แลนด์",
    nationality: "Dutch",
    tag: "#EU#",
    decision: "",
    cnFirst: "H",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Netherlands",
  },
  {
    code: "NC",
    code3: "NCL",
    cnName: "新喀里多尼亚",
    enName: "New Caledonia",
    twName: "新喀里多尼亞島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "687",
    amountLimit: null,
    currency: "USD",
    thName: "นิวแคลิโดเนีย",
    nationality: "New Caledonian",
    tag: null,
    decision: "R",
    cnFirst: "X",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "New Caledonia",
  },
  {
    code: "NZ",
    code3: "NZL",
    cnName: "新西兰",
    enName: "New Zealand",
    twName: "紐西蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: false,
    rating: "F",
    phoneCode: "64",
    amountLimit: null,
    currency: "USD",
    thName: "นิวซีแลนด์",
    nationality: "New Zealander",
    tag: null,
    decision: "X",
    cnFirst: "X",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "New Zealand",
  },
  {
    code: "NI",
    code3: "NIC",
    cnName: "尼加拉瓜",
    enName: "Nicaragua",
    twName: "尼加拉瓜",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "505",
    amountLimit: null,
    currency: "USD",
    thName: "นิการากัว",
    nationality: "Nicaraguan",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Nicaragua",
  },
  {
    code: "NE",
    code3: "NER",
    cnName: "尼日尔",
    enName: "Niger",
    twName: "尼日",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "227",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศไนเธอร์",
    nationality: "Nigerien",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Niger",
  },
  {
    code: "NG",
    code3: "NGA",
    cnName: "尼日利亚",
    enName: "Nigeria",
    twName: "奈及利亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "234",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศไนจีเรีย",
    nationality: "Nigerian",
    tag: null,
    decision: "",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Nigeria",
  },
  {
    code: "NU",
    code3: "NIU",
    cnName: "纽埃",
    enName: "Niue",
    twName: "紐埃",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "683",
    amountLimit: null,
    currency: "USD",
    thName: "นีอูเอ",
    nationality: "Niuean",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Niue",
  },
  {
    code: "NF",
    code3: "NFK",
    cnName: "诺福克岛",
    enName: "Norfolk Island",
    twName: "諾福克島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "672",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะนอร์โฟล์ค",
    nationality: "Norfolk Islander",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Norfolk Island",
  },
  {
    code: "KP",
    code3: "PRK",
    cnName: "朝鲜 (北韩)",
    enName: "North Korea",
    twName: "朝鲜 (北韓)",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "850",
    amountLimit: null,
    currency: "USD",
    thName: "เกาหลีเหนือ",
    nationality: "North Korean",
    tag: null,
    decision: "S",
    cnFirst: "C",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "North Korea",
  },
  {
    code: "MP",
    code3: "MNP",
    cnName: "北马里亚纳群岛",
    enName: "Northern Mariana Islands",
    twName: "北馬里亞納群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1670",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะนอร์เทิร์นมาเรียนา",
    nationality: "Northern Marianan",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Northern Mariana Islands",
  },
  {
    code: "NO",
    code3: "NOR",
    cnName: "挪威",
    enName: "Norway",
    twName: "挪威",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "47",
    amountLimit: null,
    currency: "USD",
    thName: "นอร์เวย์",
    nationality: "Norwegian",
    tag: null,
    decision: "",
    cnFirst: "N",
    enFirst: "N",
    companyId: 102,
    initial: "N",
    displayName: "Norway",
  },
  {
    code: "OM",
    code3: "OMN",
    cnName: "阿曼",
    enName: "Oman",
    twName: "阿曼",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "968",
    amountLimit: null,
    currency: "USD",
    thName: "โอมาน",
    nationality: "Omani",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "O",
    companyId: 102,
    initial: "O",
    displayName: "Oman",
  },
  {
    code: "PK",
    code3: "PAK",
    cnName: "巴基斯坦",
    enName: "Pakistan",
    twName: "巴基斯坦",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "92",
    amountLimit: null,
    currency: "USD",
    thName: "ปากีสถาน",
    nationality: "Pakistani",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Pakistan",
  },
  {
    code: "PW",
    code3: "PLW",
    cnName: "帕劳",
    enName: "Palau",
    twName: "帛琉",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "680",
    amountLimit: null,
    currency: "USD",
    thName: "ปาเลา",
    nationality: "Palauan",
    tag: null,
    decision: "R",
    cnFirst: "P",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Palau",
  },
  {
    code: "PS",
    code3: "PSE",
    cnName: "巴勒斯坦国",
    enName: "Palestine, State of",
    twName: "巴勒斯坦國",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "970",
    amountLimit: null,
    currency: "USD",
    thName: "รัฐปาเลสไตน์",
    nationality: "Palestinian",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Palestine, State of",
  },
  {
    code: "PA",
    code3: "PAN",
    cnName: "巴拿马",
    enName: "Panama",
    twName: "巴拿馬",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "507",
    amountLimit: null,
    currency: "USD",
    thName: "ปานามา",
    nationality: "Panamanian",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Panama",
  },
  {
    code: "PG",
    code3: "PNG",
    cnName: "巴布亚新几内亚",
    enName: "Papua New Guinea",
    twName: "巴布亞新幾內亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "675",
    amountLimit: null,
    currency: "USD",
    thName: "ปาปัวนิวกินี",
    nationality: "Papua New Guinean",
    tag: null,
    decision: "R",
    cnFirst: "B",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Papua New Guinea",
  },
  {
    code: "PY",
    code3: "PRY",
    cnName: "巴拉圭",
    enName: "Paraguay",
    twName: "巴拉圭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "595",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศปารากวัย",
    nationality: "Paraguayan",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Paraguay",
  },
  {
    code: "PE",
    code3: "PER",
    cnName: "秘鲁",
    enName: "Peru",
    twName: "秘魯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "51",
    amountLimit: null,
    currency: "USD",
    thName: "เปรู",
    nationality: "Peruvian",
    tag: null,
    decision: "",
    cnFirst: "M",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Peru",
  },
  {
    code: "PH",
    code3: "PHL",
    cnName: "菲律宾",
    enName: "Philippines",
    twName: "菲律賓",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "63",
    amountLimit: null,
    currency: "USD",
    thName: "ฟิลิปปินส์",
    nationality: "Filipino",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Philippines",
  },
  {
    code: "PN",
    code3: "PCN",
    cnName: "皮特凯恩群岛",
    enName: "Pitcairn Islands",
    twName: "皮特康島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "870",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะพิตแคร์น",
    nationality: "Pitcairn Islander",
    tag: null,
    decision: "R",
    cnFirst: "P",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Pitcairn Islands",
  },
  {
    code: "PL",
    code3: "POL",
    cnName: "波兰",
    enName: "Poland",
    twName: "波蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "48",
    amountLimit: null,
    currency: "USD",
    thName: "โปแลนด์",
    nationality: "Polish",
    tag: "#EU#",
    decision: "",
    cnFirst: "B",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Poland",
  },
  {
    code: "PT",
    code3: "PRT",
    cnName: "葡萄牙",
    enName: "Portugal",
    twName: "葡萄牙",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "351",
    amountLimit: null,
    currency: "USD",
    thName: "โปรตุเกส",
    nationality: "Portuguese",
    tag: "#EU#",
    decision: "",
    cnFirst: "P",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Portugal",
  },
  {
    code: "PR",
    code3: "PRI",
    cnName: "波多黎各",
    enName: "Puerto Rico",
    twName: "波多黎各",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "1",
    amountLimit: null,
    currency: "USD",
    thName: "เปอร์โตริโก้",
    nationality: "Puerto Rican",
    tag: null,
    decision: "",
    cnFirst: "B",
    enFirst: "P",
    companyId: 102,
    initial: "P",
    displayName: "Puerto Rico",
  },
  {
    code: "QA",
    code3: "QAT",
    cnName: "卡塔尔",
    enName: "Qatar",
    twName: "卡達",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "974",
    amountLimit: null,
    currency: "USD",
    thName: "กาตาร์",
    nationality: "Qatari",
    tag: null,
    decision: "",
    cnFirst: "K",
    enFirst: "Q",
    companyId: 102,
    initial: "Q",
    displayName: "Qatar",
  },
  {
    code: "RO",
    code3: "ROU",
    cnName: "罗马尼亚",
    enName: "Romania",
    twName: "羅馬尼亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "40",
    amountLimit: null,
    currency: "USD",
    thName: "โรมาเนีย",
    nationality: "Romanian",
    tag: "#EU#",
    decision: "",
    cnFirst: "L",
    enFirst: "R",
    companyId: 102,
    initial: "R",
    displayName: "Romania",
  },
  {
    code: "RU",
    code3: "RUS",
    cnName: "俄罗斯",
    enName: "Russia",
    twName: "俄羅斯",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "7",
    amountLimit: null,
    currency: "USD",
    thName: "รัสเซีย",
    nationality: "Russian",
    tag: null,
    decision: "",
    cnFirst: "E",
    enFirst: "R",
    companyId: 102,
    initial: "R",
    displayName: "Russia",
  },
  {
    code: "RW",
    code3: "RWA",
    cnName: "卢旺达",
    enName: "Rwanda",
    twName: "盧安達",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "250",
    amountLimit: null,
    currency: "USD",
    thName: "รวันดา",
    nationality: "Rwandan",
    tag: null,
    decision: "R",
    cnFirst: "L",
    enFirst: "R",
    companyId: 102,
    initial: "R",
    displayName: "Rwanda",
  },
  {
    code: "RE",
    code3: "REU",
    cnName: "留尼汪",
    enName: "Réunion",
    twName: "留尼旺",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "262",
    amountLimit: null,
    currency: "USD",
    thName: "เรอูนียง",
    nationality: "Réunionese",
    tag: null,
    decision: "R",
    cnFirst: "L",
    enFirst: "R",
    companyId: 102,
    initial: "R",
    displayName: "Réunion",
  },
  {
    code: "BL",
    code3: "BLM",
    cnName: "圣巴泰勒米岛",
    enName: "Saint Barthélemy",
    twName: "聖巴瑟米",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "590",
    amountLimit: null,
    currency: "USD",
    thName: "เซนต์บาร์เตเลมี",
    nationality: "Barthélemois",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saint Barthélemy",
  },
  {
    code: "SH",
    code3: "SHN",
    cnName: "圣赫勒拿岛",
    enName: "Saint Helena",
    twName: "聖赫勒拿島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "290",
    amountLimit: null,
    currency: "USD",
    thName: "เซนต์เฮเลนา",
    nationality: "Saint Helenian",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saint Helena",
  },
  {
    code: "KN",
    code3: "KNA",
    cnName: "圣基茨和尼维斯",
    enName: "Saint Kitts and Nevis",
    twName: "聖克里斯多福及尼維斯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1869",
    amountLimit: null,
    currency: "USD",
    thName: "เซนต์คิตส์และเนวิส",
    nationality: "Nevisian",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saint Kitts and Nevis",
  },
  {
    code: "LC",
    code3: "LCA",
    cnName: "圣卢西亚",
    enName: "Saint Lucia",
    twName: "聖露西亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1758",
    amountLimit: null,
    currency: "USD",
    thName: "เซนต์ลูเซีย",
    nationality: "Saint Lucian",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saint Lucia",
  },
  {
    code: "MF",
    code3: "MAF",
    cnName: "法属圣马丁",
    enName: "Saint Martin",
    twName: "法屬聖馬丁　",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1599",
    amountLimit: null,
    currency: "USD",
    thName: "เซนต์มาร์ติ",
    nationality: "Saint-Martinoise",
    tag: null,
    decision: "R",
    cnFirst: "F",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saint Martin",
  },
  {
    code: "PM",
    code3: "SPM",
    cnName: "圣皮埃尔和密克隆",
    enName: "Saint Pierre and Miquelon",
    twName: "聖皮耶與密克隆群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "508",
    amountLimit: null,
    currency: "USD",
    thName: "เซนต์ปิแอร์และมีเกอลง",
    nationality: "French",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saint Pierre and Miquelon",
  },
  {
    code: "VC",
    code3: "VCT",
    cnName: "圣文森特和格林纳丁斯",
    enName: "Saint Vincent and the Grenadines",
    twName: "聖文森及格瑞那丁",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1784",
    amountLimit: null,
    currency: "USD",
    thName: "เซนต์วินเซนต์และเกรนาดีนส์",
    nationality: "Saint Vincentian",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saint Vincent and the Grenadines",
  },
  {
    code: "WS",
    code3: "WSM",
    cnName: "萨摩亚",
    enName: "Samoa",
    twName: "薩摩亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "685",
    amountLimit: null,
    currency: "USD",
    thName: "ซามัว",
    nationality: "Samoan",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Samoa",
  },
  {
    code: "SM",
    code3: "SMR",
    cnName: "圣马力诺",
    enName: "San Marino",
    twName: "聖馬利諾",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "378",
    amountLimit: null,
    currency: "USD",
    thName: "ซานมารีโน",
    nationality: "Sammarinese",
    tag: null,
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "San Marino",
  },
  {
    code: "SA",
    code3: "SAU",
    cnName: "沙特阿拉伯",
    enName: "Saudi Arabia",
    twName: "沙烏地阿拉伯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "966",
    amountLimit: null,
    currency: "USD",
    thName: "เซาตูเมและปรินซิปี",
    nationality: "Saudi Arabian",
    tag: null,
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Saudi Arabia",
  },
  {
    code: "SN",
    code3: "SEN",
    cnName: "塞内加尔",
    enName: "Senegal",
    twName: "塞內加爾",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "221",
    amountLimit: null,
    currency: "USD",
    thName: "ซาอุดิอาราเบีย",
    nationality: "Senegalese",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Senegal",
  },
  {
    code: "RS",
    code3: "SRB",
    cnName: "塞尔维亚",
    enName: "Serbia",
    twName: "塞爾維亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "381",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศเซเนกัล",
    nationality: "Serbian",
    tag: null,
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Serbia",
  },
  {
    code: "SC",
    code3: "SYC",
    cnName: "塞舌尔",
    enName: "Seychelles",
    twName: "塞席爾",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "248",
    amountLimit: null,
    currency: "USD",
    thName: "เซอร์เบีย",
    nationality: "Seychellois",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Seychelles",
  },
  {
    code: "SL",
    code3: "SLE",
    cnName: "塞拉利昂",
    enName: "Sierra Leone",
    twName: "獅子山",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "232",
    amountLimit: null,
    currency: "USD",
    thName: "เซเชลส์",
    nationality: "Sierra Leonean",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Sierra Leone",
  },
  {
    code: "SG",
    code3: "SGP",
    cnName: "新加坡",
    enName: "Singapore",
    twName: "新加坡",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "65",
    amountLimit: null,
    currency: "USD",
    thName: "เซียร์ราลีโอน",
    nationality: "Singaporean",
    tag: null,
    decision: "",
    cnFirst: "X",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Singapore",
  },
  {
    code: "SX",
    code3: "SXM",
    cnName: "荷属圣马丁",
    enName: "Sint Maarten (Dutch part)",
    twName: "荷屬聖馬丁",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1721",
    amountLimit: null,
    currency: "USD",
    thName: "สิงคโปร์",
    nationality: "Sint Maarten",
    tag: null,
    decision: "R",
    cnFirst: "H",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Sint Maarten (Dutch part)",
  },
  {
    code: "SK",
    code3: "SVK",
    cnName: "斯洛伐克",
    enName: "Slovakia",
    twName: "斯洛伐克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "421",
    amountLimit: null,
    currency: "USD",
    thName: "ชินต์มาร์เติน (ส่วนของดัช)",
    nationality: "Slovak",
    tag: "#EU#",
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Slovakia",
  },
  {
    code: "SI",
    code3: "SVN",
    cnName: "斯洛文尼亚",
    enName: "Slovenia",
    twName: "斯洛維尼亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "386",
    amountLimit: null,
    currency: "USD",
    thName: "สโลวะเกีย",
    nationality: "Slovenian, Slovene",
    tag: "#EU#",
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Slovenia",
  },
  {
    code: "ST",
    code3: "STP",
    cnName: "圣多美和普林西比",
    enName: "So Tomé and Príncipe",
    twName: "聖多美普林西比",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "239",
    amountLimit: null,
    currency: "USD",
    thName: "สโลวีเนีย",
    nationality: "São Toméan",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "So Tomé and Príncipe",
  },
  {
    code: "SB",
    code3: "SLB",
    cnName: "所罗门群岛",
    enName: "Solomon Islands",
    twName: "索羅門群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "677",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะโซโลมอน",
    nationality: "Solomon Islander",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Solomon Islands",
  },
  {
    code: "SO",
    code3: "SOM",
    cnName: "索马里",
    enName: "Somalia, Federal Republic of",
    twName: "索馬利亞",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "252",
    amountLimit: null,
    currency: "USD",
    thName: "สหพันธ์สาธารณรัฐโซมาเลีย",
    nationality: "Somali",
    tag: null,
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Somalia, Federal Republic of",
  },
  {
    code: "ZA",
    code3: "ZAF",
    cnName: "南非",
    enName: "South Africa",
    twName: "南非",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "27",
    amountLimit: null,
    currency: "USD",
    thName: "แอฟริกาใต้",
    nationality: "South African",
    tag: null,
    decision: "",
    cnFirst: "N",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "South Africa",
  },
  {
    code: "GS",
    code3: "SGS",
    cnName: "南乔治亚岛和南桑威奇群岛",
    enName: "South Georgia and the South Sandwich Islands",
    twName: "南喬治亞與南三明治群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "500",
    amountLimit: null,
    currency: "USD",
    thName: "เซาท์จอร์เจียและหมู่เกาะเซาท์แซน",
    nationality: "South Georgia and South Sandwich Islander",
    tag: null,
    decision: "R",
    cnFirst: "N",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "KR",
    code3: "KOR",
    cnName: "韩国 (南韩)",
    enName: "South Korea",
    twName: "韓國 (南韓)",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "A",
    phoneCode: "82",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐเกาหลี",
    nationality: "South Korean",
    tag: null,
    decision: "R",
    cnFirst: "H",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "South Korea",
  },
  {
    code: "SS",
    code3: "SSD",
    cnName: "南苏丹共和国",
    enName: "South Sudan, Republic of",
    twName: "南蘇丹共和國",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "211",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐเซาท์ซูดาน",
    nationality: "South Sudanese",
    tag: null,
    decision: "",
    cnFirst: "N",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "South Sudan, Republic of",
  },
  {
    code: "ES",
    code3: "ESP",
    cnName: "西班牙",
    enName: "Spain",
    twName: "西班牙",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "34",
    amountLimit: null,
    currency: "USD",
    thName: "สเปน",
    nationality: "Spanish",
    tag: "#EU#",
    decision: "",
    cnFirst: "X",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Spain",
  },
  {
    code: "LK",
    code3: "LKA",
    cnName: "斯里兰卡",
    enName: "Sri Lanka",
    twName: "斯里蘭卡",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "94",
    amountLimit: null,
    currency: "USD",
    thName: "ศรีลังกา",
    nationality: "Sri Lankan",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Sri Lanka",
  },
  {
    code: "SD",
    code3: "SDN",
    cnName: "苏丹",
    enName: "Sudan, Republic of the",
    twName: "蘇丹",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "249",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐเซาท์ซูดาน",
    nationality: "Sudanese",
    tag: null,
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Sudan, Republic of the",
  },
  {
    code: "SR",
    code3: "SUR",
    cnName: "苏里南",
    enName: "Suriname",
    twName: "蘇利南",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "597",
    amountLimit: null,
    currency: "USD",
    thName: "ซูรินาเม",
    nationality: "Surinamese",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Suriname",
  },
  {
    code: "SJ",
    code3: "SJM",
    cnName: "斯瓦尔巴群岛和扬马延岛",
    enName: "Svalbard and Jan Mayen",
    twName: "斯瓦巴及尖棉島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "47",
    amountLimit: null,
    currency: "USD",
    thName: "สฟาลบาร์และยานไมเอน",
    nationality: "Svalbard",
    tag: null,
    decision: "",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Svalbard and Jan Mayen",
  },
  {
    code: "SZ",
    code3: "SWZ",
    cnName: "斯威士兰",
    enName: "Swaziland",
    twName: "史瓦濟蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "268",
    amountLimit: null,
    currency: "USD",
    thName: "สวาซิแลนด์",
    nationality: "Swazi",
    tag: null,
    decision: "R",
    cnFirst: "S",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Swaziland",
  },
  {
    code: "SE",
    code3: "SWE",
    cnName: "瑞典",
    enName: "Sweden",
    twName: "瑞典",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "46",
    amountLimit: null,
    currency: "USD",
    thName: "สวีเดน",
    nationality: "Swedish",
    tag: "#EU#",
    decision: "",
    cnFirst: "R",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Sweden",
  },
  {
    code: "CH",
    code3: "CHE",
    cnName: "瑞士",
    enName: "Switzerland",
    twName: "瑞士",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "41",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศสวิสเซอร์แลนด์",
    nationality: "Swiss",
    tag: null,
    decision: "",
    cnFirst: "R",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Switzerland",
  },
  {
    code: "SY",
    code3: "SYR",
    cnName: "叙利亚",
    enName: "Syria",
    twName: "敘利亞",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "963",
    amountLimit: null,
    currency: "USD",
    thName: "ซีเรีย",
    nationality: "Syrian",
    tag: null,
    decision: "S",
    cnFirst: "X",
    enFirst: "S",
    companyId: 102,
    initial: "S",
    displayName: "Syria",
  },
  {
    code: "TW",
    code3: "TWN",
    cnName: "台湾",
    enName: "Taiwan",
    twName: "臺灣",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "886",
    amountLimit: null,
    currency: "USD",
    thName: "จีนไทเป",
    nationality: "Taiwanese",
    tag: null,
    decision: "",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Taiwan",
  },
  {
    code: "TJ",
    code3: "TJK",
    cnName: "塔吉克斯坦斯坦",
    enName: "Tajikistan",
    twName: "塔吉克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "992",
    amountLimit: null,
    currency: "USD",
    thName: "ทาจิกิสถาน",
    nationality: "Tajikistani",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Tajikistan",
  },
  {
    code: "TZ",
    code3: "TZA",
    cnName: "坦桑尼亚",
    enName: "Tanzania",
    twName: "坦尚尼亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "255",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศแทนซาเนีย",
    nationality: "Tanzanian",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Tanzania",
  },
  {
    code: "TH",
    code3: "THA",
    cnName: "泰国",
    enName: "Thailand",
    twName: "泰國",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "66",
    amountLimit: null,
    currency: "THB",
    thName: "ประเทศไทย",
    nationality: "Thai",
    tag: null,
    decision: "",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Thailand",
  },
  {
    code: "TL",
    code3: "TLS",
    cnName: "东帝汶",
    enName: "Timor-Leste",
    twName: "東帝汶",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "670",
    amountLimit: null,
    currency: "USD",
    thName: "ติมอร์เลสเต",
    nationality: "Timorese",
    tag: null,
    decision: "",
    cnFirst: "D",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Timor-Leste",
  },
  {
    code: "TG",
    code3: "TGO",
    cnName: "多哥",
    enName: "Togo",
    twName: "多哥",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "228",
    amountLimit: null,
    currency: "USD",
    thName: "โตโก",
    nationality: "Togolese",
    tag: null,
    decision: "R",
    cnFirst: "D",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Togo",
  },
  {
    code: "TK",
    code3: "TKL",
    cnName: "托克劳",
    enName: "Tokelau",
    twName: "托克勞群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "690",
    amountLimit: null,
    currency: "USD",
    thName: "โตเกเลา",
    nationality: "Tokelauan",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Tokelau",
  },
  {
    code: "TO",
    code3: "TON",
    cnName: "汤加",
    enName: "Tonga",
    twName: "東加",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "676",
    amountLimit: null,
    currency: "USD",
    thName: "ตองกา",
    nationality: "Tongan",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Tonga",
  },
  {
    code: "TT",
    code3: "TTO",
    cnName: "特立尼达和多巴哥",
    enName: "Trinidad and Tobago",
    twName: "千里達及托巴哥",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1868",
    amountLimit: null,
    currency: "USD",
    thName: "ตรินิแดดและโตเบโก",
    nationality: "Trinidadian and Tobagonian",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Trinidad and Tobago",
  },
  {
    code: "TN",
    code3: "TUN",
    cnName: "突尼斯",
    enName: "Tunisia",
    twName: "突尼西亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "216",
    amountLimit: null,
    currency: "USD",
    thName: "ตูนิเซีย",
    nationality: "Tunisian",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Tunisia",
  },
  {
    code: "TR",
    code3: "TUR",
    cnName: "土耳其",
    enName: "Turkey",
    twName: "土耳其",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "90",
    amountLimit: null,
    currency: "USD",
    thName: "ตุรกี",
    nationality: "Turkish",
    tag: null,
    decision: "",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Turkey",
  },
  {
    code: "TM",
    code3: "TKM",
    cnName: "土库曼斯坦",
    enName: "Turkmenistan",
    twName: "土庫曼",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "993",
    amountLimit: null,
    currency: "USD",
    thName: "เติร์กเมนิสถาน",
    nationality: "Turkmen",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Turkmenistan",
  },
  {
    code: "TC",
    code3: "TCA",
    cnName: "特克斯和凯科斯群岛",
    enName: "Turks and Caicos Islands",
    twName: "土克斯及開科斯群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "1649",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะเติกส์และหมู่เกาะเคคอส",
    nationality: "Turks and Caicos Islander",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Turks and Caicos Islands",
  },
  {
    code: "TV",
    code3: "TUV",
    cnName: "图瓦卢",
    enName: "Tuvalu",
    twName: "吐瓦魯",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "688",
    amountLimit: null,
    currency: "USD",
    thName: "ตูวาลู",
    nationality: "Tuvaluan",
    tag: null,
    decision: "R",
    cnFirst: "T",
    enFirst: "T",
    companyId: 102,
    initial: "T",
    displayName: "Tuvalu",
  },
  {
    code: "UM",
    code3: "UMI",
    cnName: "美国本土外小岛屿",
    enName: "U.S. Minor Outlying Islands",
    twName: "美國邊疆小島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: false,
    rating: "C",
    phoneCode: "699",
    amountLimit: null,
    currency: "USD",
    thName: "เกาะเล็กรอบนอกของสหรัฐอเมริกา",
    nationality: "American",
    tag: null,
    decision: "X",
    cnFirst: "M",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "U.S. Minor Outlying Islands",
  },
  {
    code: "VI",
    code3: "VIR",
    cnName: "美属维尔京群岛",
    enName: "U.S. Virgin Islands",
    twName: "美屬維京群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: false,
    rating: "C",
    phoneCode: "1340",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
    nationality: "U.S. Virgin Islander",
    tag: null,
    decision: "X",
    cnFirst: "M",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "U.S. Virgin Islands",
  },
  {
    code: "UG",
    code3: "UGA",
    cnName: "乌干达",
    enName: "Uganda",
    twName: "烏干達",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "256",
    amountLimit: null,
    currency: "USD",
    thName: "ยูกันดา",
    nationality: "Ugandan",
    tag: null,
    decision: "R",
    cnFirst: "W",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "Uganda",
  },
  {
    code: "UA",
    code3: "UKR",
    cnName: "乌克兰",
    enName: "Ukraine",
    twName: "烏克蘭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "380",
    amountLimit: null,
    currency: "USD",
    thName: "ยูเครน",
    nationality: "Ukrainian",
    tag: null,
    decision: "",
    cnFirst: "W",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "Ukraine",
  },
  {
    code: "AE",
    code3: "ARE",
    cnName: "阿拉伯联合酋长国",
    enName: "United Arab Emirates",
    twName: "阿拉伯聯合大公國",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "971",
    amountLimit: null,
    currency: "USD",
    thName: "สหรัฐอาหรับเอมิเรตส์",
    nationality: "Emirati",
    tag: null,
    decision: "",
    cnFirst: "A",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "United Arab Emirates",
  },
  {
    code: "GB",
    code3: "GBR",
    cnName: "英国",
    enName: "United Kingdom",
    twName: "英國",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "44",
    amountLimit: 0,
    currency: "USD",
    thName: "ประเทศอังกฤษ",
    nationality: "British",
    tag: "#EU#",
    decision: "",
    cnFirst: "Y",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "United Kingdom",
  },
  {
    code: "US",
    code3: "USA",
    cnName: "美国",
    enName: "United States of America",
    twName: "美國",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: false,
    rating: "F",
    phoneCode: "1",
    amountLimit: 0,
    currency: "USD",
    thName: "สหรัฐอาหรับเอมิเรตส์",
    nationality: "American",
    tag: null,
    decision: "X",
    cnFirst: "M",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "United States of America",
  },
  {
    code: "UY",
    code3: "URY",
    cnName: "乌拉圭",
    enName: "Uruguay",
    twName: "烏拉圭",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "598",
    amountLimit: null,
    currency: "USD",
    thName: "อุรุกวัย",
    nationality: "Uruguayan",
    tag: null,
    decision: "R",
    cnFirst: "W",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "Uruguay",
  },
  {
    code: "UZ",
    code3: "UZB",
    cnName: "乌兹别克斯坦",
    enName: "Uzbekistan",
    twName: "烏茲別克",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "998",
    amountLimit: null,
    currency: "USD",
    thName: "อุซเบกิ",
    nationality: "Uzbekistani",
    tag: null,
    decision: "R",
    cnFirst: "W",
    enFirst: "U",
    companyId: 102,
    initial: "U",
    displayName: "Uzbekistan",
  },
  {
    code: "VU",
    code3: "VUT",
    cnName: "瓦努阿图",
    enName: "Vanuatu",
    twName: "萬那杜",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "678",
    amountLimit: null,
    currency: "USD",
    thName: "วานูอาตู",
    nationality: "Ni-Vanuatu",
    tag: null,
    decision: "",
    cnFirst: "W",
    enFirst: "V",
    companyId: 102,
    initial: "V",
    displayName: "Vanuatu",
  },
  {
    code: "VA",
    code3: "VAT",
    cnName: "梵蒂冈",
    enName: "Vatican City",
    twName: "梵蒂岡",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "379",
    amountLimit: null,
    currency: "USD",
    thName: "นครวาติกัน",
    nationality: "Vatican",
    tag: null,
    decision: "",
    cnFirst: "F",
    enFirst: "V",
    companyId: 102,
    initial: "V",
    displayName: "Vatican City",
  },
  {
    code: "VE",
    code3: "VEN",
    cnName: "委内瑞拉",
    enName: "Venezuela",
    twName: "委內瑞拉",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "58",
    amountLimit: null,
    currency: "USD",
    thName: "เวเนซุเอลา",
    nationality: "Venezuelan",
    tag: null,
    decision: "",
    cnFirst: "W",
    enFirst: "V",
    companyId: 102,
    initial: "V",
    displayName: "Venezuela",
  },
  {
    code: "VN",
    code3: "VNM",
    cnName: "越南",
    enName: "Vietnam",
    twName: "越南",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "84",
    amountLimit: null,
    currency: "VND",
    thName: "เวียดนาม",
    nationality: "Vietnamese",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "V",
    companyId: 102,
    initial: "V",
    displayName: "Vietnam",
  },
  {
    code: "WF",
    code3: "WLF",
    cnName: "瓦利斯和富图纳",
    enName: "Wallis and Futuna",
    twName: "沃里斯與伏塔那島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "681",
    amountLimit: null,
    currency: "USD",
    thName: "วาลลิสและฟุตูนา",
    nationality: "Wallisian or Futunan",
    tag: null,
    decision: "R",
    cnFirst: "W",
    enFirst: "W",
    companyId: 102,
    initial: "W",
    displayName: "Wallis and Futuna",
  },
  {
    code: "EH",
    code3: "ESH",
    cnName: "西撒哈拉",
    enName: "Western Sahara",
    twName: "西撒哈拉",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "212",
    amountLimit: null,
    currency: "USD",
    thName: "เวสเทิร์นสะฮารา",
    nationality: "Sahraouian",
    tag: null,
    decision: "R",
    cnFirst: "X",
    enFirst: "W",
    companyId: 102,
    initial: "W",
    displayName: "Western Sahara",
  },
  {
    code: "YE",
    code3: "YEM",
    cnName: "也门共和国",
    enName: "Yemen, Republic of",
    twName: "葉門共和國",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "967",
    amountLimit: null,
    currency: "USD",
    thName: "สาธารณรัฐเยเมน",
    nationality: "Yemeni",
    tag: null,
    decision: "",
    cnFirst: "Y",
    enFirst: "Y",
    companyId: 102,
    initial: "Y",
    displayName: "Yemen, Republic of",
  },
  {
    code: "ZM",
    code3: "ZMB",
    cnName: "赞比亚",
    enName: "Zambia",
    twName: "尚比亞",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "C",
    phoneCode: "260",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศแซมเบีย",
    nationality: "Zambian",
    tag: null,
    decision: "",
    cnFirst: "Z",
    enFirst: "Z",
    companyId: 102,
    initial: "Z",
    displayName: "Zambia",
  },
  {
    code: "ZW",
    code3: "ZWE",
    cnName: "津巴布韦",
    enName: "Zimbabwe",
    twName: "辛巴威",
    status: true,
    ipSubmit: true,
    countrySubmit: false,
    nationalitySubmit: true,
    rating: "F",
    phoneCode: "263",
    amountLimit: null,
    currency: "USD",
    thName: "ประเทศซิมบับเว",
    nationality: "Zimbabwean",
    tag: null,
    decision: "",
    cnFirst: "J",
    enFirst: "Z",
    companyId: 102,
    initial: "Z",
    displayName: "Zimbabwe",
  },
  {
    code: "AX",
    code3: "ALA",
    cnName: "奥兰群岛",
    enName: "land Islands",
    twName: "奧蘭群島",
    status: true,
    ipSubmit: true,
    countrySubmit: true,
    nationalitySubmit: true,
    rating: "B",
    phoneCode: "358",
    amountLimit: null,
    currency: "USD",
    thName: "หมู่เกาะโอลันด์",
    nationality: "Åland Islander",
    tag: null,
    decision: "R",
    cnFirst: "A",
    enFirst: "L",
    companyId: 102,
    initial: "L",
    displayName: "land Islands",
  },
];
