import SectionHeader from "./components/SectionHeader";
import Link from "@mui/material/Link";
import { StyledDivider } from "../StyledDivider/StyledDivider";
import { Box, Button, Typography, styled } from "@mui/material";
import MobileSectionHeader from "./components/MobileSectionHeader";

const LimitedRoot = styled("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [props.theme.breakpoints.down("lg")]: {
    width: "100%",
    maxWidth: 1100,
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const LimitedBg = styled("div")((props) => ({
  backgroundImage: "url(/images/small/limited_bg.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% auto",
  width: 820,
  height: "auto",
  padding: "70px 80px 30px",
  textAlign: "center",
  // [props.theme.breakpoints.down(1200)]: {
  //   width: "100%",
  //   height: "auto",
  //   padding: 50,
  // },
  [props.theme.breakpoints.down(768)]: {
    padding: 30,
  },
  [props.theme.breakpoints.down(600)]: {
    backgroundSize: "cover",
    padding: "30px 20px",
  },
}));

const TitleLine = styled("div")((props) => ({
  width: "100%",
  position: "relative",
  textAlign: "center",
  fontFamily: "SourceHanSerifCN",
  fontStyle: "italic",
  color: "#FFFFFF",
  fontSize: 32,
  fontWeight: 600,
  lineHeight: "32px",
  "&:before": {
    position: "absolute",
    content: "''",
    background: "url(/images/small/limited_left.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: 24,
    height: 40,
    left: 0,
  },
  "&:after": {
    position: "absolute",
    content: "''",
    background: "url(/images/small/limited_right.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: 24,
    height: 40,
    right: 0,
  },
  [props.theme.breakpoints.down(960)]: {
    fontSize: 28,
    lineHeight: "28px",
    "&:before": {
      width: 20,
      height: 30,
    },
    "&:after": {
      width: 20,
      height: 30,
    },
  },
  [props.theme.breakpoints.down(768)]: {
    fontSize: 24,
    lineHeight: "26px",
    "&:before": {
      width: 18,
      height: 26,
    },
    "&:after": {
      width: 18,
      height: 26,
    },
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 15,
    lineHeight: "24px",
    "&:before": {
      width: 16,
      height: 24,
    },
    "&:after": {
      width: 16,
      height: 24,
    },
  },
}));

const BodyText = styled(Typography)((props) => ({
  textAlign: "center",
  margin: "30px 0",
  fontSize: 20,
  fontStyle: "italic",
  color: "#ffffff",
  textDecoration: "underline",
  textDecorationThickness: 1,
  textDecorationColor: "#FF0000",
  "&>span": {
    color: "#FF0000",
  },
  [props.theme.breakpoints.down(768)]: {
    fontSize: 16,
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 12,
    margin: 20,
  },
}));

const RedButton = styled(Button)((props) => ({
  width: 240,
  height: 50,
  color: "#FFFFFF",
  background: "#da2027",
  borderRadius: 14,
  fontSize: 24,
  fontWeight: 600,
  "&:hover": {
    background: "#da2027",
    opacity: 0.8,
  },
  // [props.theme.breakpoints.down(1200)]: {
  //   height: 60,
  // },
  [props.theme.breakpoints.down(820)]: {
    height: 40,
    fontSize: 20,
    borderRadius: 10,
  },
  [props.theme.breakpoints.down(600)]: {
    height: 30,
    fontSize: 14,
    width: 150,
  },
}));

export default function Limited() {
  return (
    <>
      <Box sx={{ display: { sm: "block", xs: "none" } }}>
        <SectionHeader
          bkUrl="/images/small/divider3.png"
          imgUrl="/images/small/xianliang.png"
        />
      </Box>
      <Box sx={{ display: { sm: "none", xs: "block" } }}>
        <MobileSectionHeader
          bkUrl="/images/mobileBig/divider3.png"
          imgUrl="/images/mobileBig/xianliang.png"
        />
      </Box>
      <StyledDivider height={70} mHeight={50} />
      <LimitedRoot>
        <LimitedBg>
          <TitleLine>要投资产品, 更要投资自己, 创造可能性</TitleLine>
          <BodyText>
            每月
            <span>前30名</span>
            成功报名的AETOS艾拓思客户 ,
            <br />
            可与AETOS艾拓思资本集团投研部主管Glenn Yin
            <span>一对一</span>
            答疑解惑。
          </BodyText>
          <Link
            href="https://member.aetoscg.com/cn/register-live.htm"
            color="inherit"
            underline="none"
            target="_blank"
          >
            <RedButton>点击报名</RedButton>
          </Link>
        </LimitedBg>
      </LimitedRoot>
      <StyledDivider height={70} mHeight={50} />
    </>
  );
}
