import { Typography, styled } from "@mui/material";

const Root = styled("div")((props) => ({
  maxWidth: 650,
  marginRight: 30,
  color: "#ffffff",
  [props.theme.breakpoints.down("lg")]: {
    width: "100%",
    marginBottom: 60,
    marginRight: 0
  },
  [props.theme.breakpoints.down(768)]: {
    marginBottom: 40,
  }
}));

const FirstLine = styled(Typography)((props) => ({
  fontSize: 32,
  fontWeight: 700,
  "&>span": {
    "&.ai": {
      marginLeft: 15,
      fontSize: 36,
      fontFamily: "sans-serif",
      fontWeight: 700,
      letterSpacing: 10,
      display: 'inline-block',
      transform: 'scale(1.3,1)',
      msTransform: 'scale(1.3,1)',
      webkitTransform: 'scale(1.3,1)',
      mozTransform: 'scale(1.3,1)',
      oTransform: 'scale(1.3,1)'
    },
    "&.tab": {
      marginLeft: 30,
      fontSize: 26,
      fontWeight: 400,
      color: "#000000",
      padding: "5px 10px",
      background: "#ffffff",
    }
  },
  [props.theme.breakpoints.down("lg")]: {
    fontSize: 28,
    "&>span": {
      "&.ai": {
        fontSize: 32,
      },
      "&.tab": {
        fontSize: 20,
      }
    }
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 18,
    "&>span": {
      "&.ai": {
        fontSize: 22,
        letterSpacing: 1,
        marginRight: 5,
        marginLeft: 5,
      },
      "&.tab": {
        fontSize: 12,
        marginLeft: 15,
      }
    }
  },
}));

const SecondLine = styled(Typography)((props) => ({
  fontSize: 30,
  marginTop: 50,
  marginBottom: 10,
  color: "#e2e2e2",
  [props.theme.breakpoints.down("lg")]: {
    fontSize: 26,
    marginTop: 20,
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 16,
  },
}));

const BigLine = styled(Typography)((props) => ({
  fontFamily: "SourceHanSerifCN",
  fontSize: 90,
  fontWeight: 900,
  lineHeight: "120px",
  "&>span": {
    "&.star": {
      position: "relative",
      "&:after": {
        content: '""',
        width: 112,
        height: 129,
        top: -10,
        position: "absolute",
        background: "url(/images/big/banner_icon.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
      }
    },
    "&.number": {
      letterSpacing: 2,
      display: 'inline-block',
      transform: 'scale(1.3,1)',
      marginRight: 40,
      marginLeft: 20,
    }
  },
  [props.theme.breakpoints.down("lg")]: {
    fontSize: 60,
    lineHeight: "80px",
    "&>span": {
      "&.star": {
        "&:after": {
          width: 50,
          height: 65,
          top: -5,
        }
      },
    }
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 50,
    lineHeight: "65px",
  },
}));

const LastLine = styled(Typography)((props) => ({
  marginTop: 40,
  lineHeight: 1.25,
  fontSize: 32,
  "&>span": {
    fontSize: 22,
    lineHeight: 1.6,
    marginLeft: 20,
  },
  [props.theme.breakpoints.down("lg")]: {
    marginTop: 20,
    fontSize: 28,
    "&>span": {
      fontSize: 18
    }
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 18,
    "&>span": {
      fontSize: 11,
      marginLeft: 10,
      letterSpacing: -0.5,
    }
  },
}))

const LeftContent = () => {
  return (
    <Root>
      <FirstLine variant="p">
        抓住
        <span className="ai">AI</span>
        经济大事件红利
        <span className="tab">布局2023下半年</span>
      </FirstLine>
      <SecondLine>AETOS独家短线交易课程</SecondLine>
      <BigLine variant="h5">
        <span className="star">狙击每月</span>
        <br />
        <span className="number">1000</span>
        点行情
      </BigLine>
      <LastLine>
        妙用经济数据
        <span>别人眼中的财经新闻 / 变成你手中的投资工具</span>
      </LastLine>
    </Root>
  )
}

export default LeftContent
