import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { styled } from "@mui/material";
import { isPopulatedArray } from "../utils";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner";
import MainContent from "../components/MainContent/MainContent";

const Divider = styled("div")((props) => ({
  height: 80,
  [props.theme.breakpoints.down(600)]: {
    height: 95,
  },
}));

export default function YouTubeLP() {
  const [params] = useSearchParams();
  const [uid, setUid] = useState("");

  useEffect(() => {
    if (isPopulatedArray(params.getAll("ref"))) {
      setUid(params.getAll("ref")[0]);
    }
  }, [params]);

  return (
    <>
      <Header />
      <Divider />
      <Banner uid={uid} />
      <MainContent />
      <Footer />
    </>
  );
}
