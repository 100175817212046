import { Container } from "@mui/material";
import { styled } from "@mui/material";

const SectionWrapper = styled("div")((props) => ({
  position: "relative",
  backgroundImage: `url(${props.url})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  width: "100%",
  height: 90,
  [props.theme.breakpoints.down(900)]: {
    height: 55,
  },
}));

const ImageWrapper = styled("div")((props) => ({
  height: 80,
  paddingLeft: 140,
  [props.theme.breakpoints.down(900)]: {
    maxWidth: 600,
    height: 50,
    paddingLeft: 100,
  },
}));

export default function SectionHeader({ bkUrl, imgUrl }) {
  return (
    <Container maxWidth="lg" disableGutters={true}>
      <SectionWrapper url={bkUrl}>
        <ImageWrapper>
          <img src={imgUrl} alt="Section1" height="100%" />
        </ImageWrapper>
      </SectionWrapper>
    </Container>
  );
}
