import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import { StyledDivider } from "../../StyledDivider/StyledDivider";
import itemIcon from "../../../assets/images/item_icon.png";

const Root = styled("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const RootWrapper = styled("div")((props) => ({
  maxWidth: 820,
  display: "flex",
  flexDirection: "row",
  [props.theme.breakpoints.down(820)]: {
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
  },
  [props.theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ImageWrapper = styled("div")((props) => ({
  position: "relative",
  width: 240,
  height: "100%",
  marginRight: 15,
  // "&>img": {
  //   width: "100%",
  //   height: "auto",
  // },
  [props.theme.breakpoints.down(820)]: {
    width: "100%",
    height: "100%",
    marginRight: 0,
  },
}));

const ExpertTextOuterWrapper = styled("div")((props) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "90%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
}));

const ExpertTextInnerWrapper = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  marginLeft: 60,
  [props.theme.breakpoints.down(720)]: {
    marginLeft: 40,
  },
  [props.theme.breakpoints.down(600)]: {
    marginLeft: 30,
  },
  [props.theme.breakpoints.down(480)]: {
    marginLeft: 20,
  },
  [props.theme.breakpoints.down(400)]: {
    marginLeft: 15,
  },
}));

const ExpertTitleWrapper = styled(Typography)((props) => ({
  fontSize: 40,
  fontWeight: 700,
  [props.theme.breakpoints.down(720)]: {
    fontSize: 32,
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 26,
  },
  [props.theme.breakpoints.down(480)]: {
    fontSize: 20,
  },
}));

const ExpertSubtitleTitleWrapper = styled(Typography)((props) => ({
  fontSize: 24,
  backgroundColor: "#000000",
  color: "#ffffff",
  padding: "5px 10px",
  marginTop: 10,
  [props.theme.breakpoints.down(720)]: {
    fontSize: 18,
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 16,
  },
  [props.theme.breakpoints.down(480)]: {
    fontSize: 12,
    marginTop: 2,
  },
  [props.theme.breakpoints.down(400)]: {
    padding: "4px 8px",
  },
}));

const SectionWrapper = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  width: 575,
  [props.theme.breakpoints.down(820)]: {
    width: "100%",
  },
}));

const BannerWrapper = styled("div")((props) => ({
  position: "relative",
  height: 145,
  backgroundColor: "#020202",
  [props.theme.breakpoints.down("sm")]: {
    height: 100,
  },
}));

const TitleWrapper = styled("div")((props) => ({
  position: "absolute",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  paddingLeft: 30,
  zIndex: 28,
  [props.theme.breakpoints.down("sm")]: {
    paddingLeft: 10,
  },
}));

const TitleTextWrapper = styled(Typography)((props) => ({
  fontSize: 34,
  color: "rgb(255, 255, 255)",
  lineHeight: 1.5,
  "& span": {
    fontFamily: "SourceHanSerifCN",
  },
  [props.theme.breakpoints.down("sm")]: {
    fontSize: 22,
  },
}));

const BackgroundWrapper = styled("div")((props) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  top: 0,
  right: 0,
  zIndex: 18,
}));

const BackgroundTextWrapper = styled(Typography)((props) => ({
  fontFamily: "SourceHanSerifCN",
  fontSize: 70,
  color: "rgb(38, 38, 38)",
  fontWeight: "bold",
  lineHeight: 1,
  [props.theme.breakpoints.down("sm")]: {
    fontSize: 46,
  },
}));

const RowWrapper = styled("div")((props) => ({
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  [props.theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
  },
}));

const RowImageWrapper = styled(Typography)((props) => ({
  width: 22,
  [props.theme.breakpoints.down("sm")]: {
    width: 14,
  },
}));

const TextWrapper = styled(Typography)((props) => ({
  marginLeft: 10,
  fontSize: 22,
  color: "rgb(0, 0, 0)",
  lineHeight: 1.8,
  // [props.theme.breakpoints.down(1200)]: {
  //   fontSize: 24,
  // },
  // [props.theme.breakpoints.down(1100)]: {
  //   fontSize: 22,
  // },
  [props.theme.breakpoints.down(820)]: {
    marginLeft: 15,
    fontSize: 20,
  },
  [props.theme.breakpoints.down("sm")]: {
    marginLeft: 10,
    fontSize: 12,
  },
}));

const showTextRow = (value) => {
  return (
    <>
      <RowWrapper>
        <RowImageWrapper>
          <img src={itemIcon} alt="" width="100%" />
        </RowImageWrapper>
        <TextWrapper>{value}</TextWrapper>
      </RowWrapper>
      <StyledDivider height={10} />
    </>
  );
};

export default function IntroSection() {
  const transferSize = useMediaQuery((theme) => theme.breakpoints.down(820));
  return (
    <Root>
      <RootWrapper>
        {/* <ImageWrapper sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
          <img src="/images/small/glenn_yin.png" alt="Glenn Yin" width="100%" />
        </ImageWrapper> */}
        <ImageWrapper>
          <img
            src={
              transferSize
                ? "/images/mobileSmall/glenn_yin.png"
                : "/images/small/glenn_yin.png"
            }
            alt="Glenn Yin"
            width="100%"
          />
          {transferSize && (
            <ExpertTextOuterWrapper>
              <Box></Box>
              <ExpertTextInnerWrapper>
                <ExpertTitleWrapper>Glenn Yin</ExpertTitleWrapper>
                <ExpertSubtitleTitleWrapper>
                  AETOS艾拓思资本集团
                </ExpertSubtitleTitleWrapper>
                <ExpertSubtitleTitleWrapper>
                  投研部主管
                </ExpertSubtitleTitleWrapper>
              </ExpertTextInnerWrapper>
            </ExpertTextOuterWrapper>
          )}
        </ImageWrapper>
        <SectionWrapper>
          <BannerWrapper>
            <TitleWrapper>
              <TitleTextWrapper>
                <span>Glenn Yin</span>
              </TitleTextWrapper>
              <TitleTextWrapper>是一位经验丰富的金融专业人士</TitleTextWrapper>
            </TitleWrapper>
            <BackgroundWrapper>
              <BackgroundTextWrapper>Glenn</BackgroundTextWrapper>
              <BackgroundTextWrapper>Yin</BackgroundTextWrapper>
            </BackgroundWrapper>
          </BannerWrapper>

          <StyledDivider height={20} mHeight={15} />
          {showTextRow("澳大利亚证券及投资委员会(ASIC)认证股票经纪人")}
          {showTextRow("澳大利亚股票经纪人及理财顾问协会(SAFAA)成员")}
          {showTextRow("曾在哈佛商学院(HBS)进修、为剑桥大学研究院受邀人")}
          {showTextRow("曾任职英国富时(FTSE)上市金融公司交易员")}
          {showTextRow("持有RG146、ADA1、ADA2等金融衍生产品交易资格")}
        </SectionWrapper>
      </RootWrapper>
    </Root>
  );
}
