import { Typography, styled, Button, Container } from "@mui/material";
import Link from "@mui/material/Link";

const RootWrapper = styled("div")((props) => ({
  position: "fixed",
  zIndex: 100,
  width: "100%",
  minHeight: 80,
  backgroundColor: "#ffffff",
}));

const HeaderWrapper = styled("div")((props) => ({
  maxWidth: 1100,
  display: "flex",
  margin: "auto",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: 80,
  [props.theme.breakpoints.down(1120)]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [props.theme.breakpoints.down(600)]: {
    flexDirection: "column",
    alignItems: "baseline",
    minHeight: 90,
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

const ImageWrapper = styled(Link)((props) => ({
  height: 70,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  paddingTop: 5,
  paddingBottom: 5,
  [props.theme.breakpoints.down(700)]: {
    height: 50,
  },
}));

const TopMenuWrapper = styled("div")((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  [props.theme.breakpoints.down(800)]: {
    width: "100%",
    justifyContent: "flex-end",
  },
}));

const MenuItemWrapper = styled(Typography)((props) => ({
  fontSize: 20,
  marginLeft: 30,
  [props.theme.breakpoints.down(700)]: {
    fontSize: 18,
    marginLeft: 15,
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 16,
    marginLeft: 15,
  },
}));

const ButtonWrapper = styled(Button)((props) => ({
  minWidth: 82,
  height: 40,
  fontSize: 20,
  marginLeft: 30,
  color: "#ffffff",
  backgroundColor: "#a51c28",
  "&:hover": {
    backgroundColor: "#DA2027",
  },
  [props.theme.breakpoints.down(600)]: {
    fontSize: 16,
    marginLeft: 20,
    height: 35,
    minWidth: 90,
  },
}));

export default function Header() {
  return (
    <RootWrapper>
      <Container maxWidth="lg" disableGutters={true}>
        <HeaderWrapper>
          <ImageWrapper
            href="https://www.aetoscg.com/zh/index.html"
            color="inherit"
            underline="none"
            target="_blank"
          >
            <img src="/images/big/logo.png" alt="Logo" height="100%" />
          </ImageWrapper>
          <TopMenuWrapper>
            <Link
              href="https://member.aetoscg.com/cn/register-demo.htm"
              color="inherit"
              underline="none"
              target="_blank"
            >
              <MenuItemWrapper>模拟注册</MenuItemWrapper>
            </Link>
            <MenuItemWrapper>
              <Link
                href="https://member.aetoscg.com/cn/register-live.htm"
                color="inherit"
                underline="none"
                target="_blank"
              >
                真实注册
              </Link>
            </MenuItemWrapper>
            <MenuItemWrapper>
              <Link
                href="https://member.aetoscg.com/cn/login.htm"
                color="inherit"
                underline="none"
                target="_blank"
              >
                入金
              </Link>
            </MenuItemWrapper>
            <Link
              href="https://www.aetoscg.com/zh/live-chat-box.html"
              color="inherit"
              underline="none"
              target="_blank"
            >
              <ButtonWrapper>在线客服</ButtonWrapper>
            </Link>
          </TopMenuWrapper>
        </HeaderWrapper>
      </Container>
    </RootWrapper>
  );
}
