import { Box, Container } from "@mui/material";
import { StyledDivider } from "../StyledDivider/StyledDivider";
import SectionHeader from "./components/SectionHeader";
import IntroSection from "./components/IntroSection";
import CarouselSection from "./components/CarouselSection";
import MobileSectionHeader from "./components/MobileSectionHeader";

export default function Experienced() {
  return (
    <>
      <Box sx={{ display: { sm: "block", xs: "none" } }}>
        <SectionHeader
          bkUrl="/images/small/divider2.png"
          imgUrl="/images/small/zishen.png"
        />
      </Box>
      <Box sx={{ display: { sm: "none", xs: "block" } }}>
        <MobileSectionHeader
          bkUrl="/images/mobileBig/divider2.png"
          imgUrl="/images/mobileBig/zishen.png"
        />
      </Box>
      <StyledDivider height={30} />
      <Container maxWidth="lg" disableGutters={true}>
        <IntroSection />
        <StyledDivider height={30} />
        <CarouselSection />
      </Container>
      <StyledDivider height={100} mHeight={50} />
    </>
  );
}
