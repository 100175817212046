import { Box } from "@mui/material";
import { styled } from "@mui/material";

const SectionWrapper = styled("div")((props) => ({
  position: "absolute",
  width: "100%",
  height: 78,
  top: -2,
  backgroundImage: `url("/images/small/divider.jpg")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "100% 100%",
  zIndex: 2,
  [props.theme.breakpoints.down("sm")]: {
    // backgroundSize: "100% auto",
    height: 40,
  },
}));

export default function SectionBanner() {
  return (
    <Box sx={{ position: "relative" }}>
      {/* <StyledDivider height={78} mHeight={30} /> */}
      <SectionWrapper />
    </Box>
  );
}
