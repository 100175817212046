import { Link, Typography, styled } from "@mui/material";

const CopyrightWrapper = styled("div")((props) => ({
  backgroundColor: "#4d4d4d",
  color: "#ffffff",
  paddingTop: 15,
  paddingBottom: 25,
  textAlign: "center",
  "& *": { fontFamily: "Georgia !important" },
}));

const TextWrapper = styled(Typography)((props) => ({
  fontSize: 14,
  lineHeight: 2,
  fontWeight: 500,
  [props.theme.breakpoints.down(600)]: {
    fontSize: 11,
    fontWeight: 400,
    letterSpacing: -0.2,
  },
}));

export default function Copyright() {
  return (
    <>
      <CopyrightWrapper>
        <TextWrapper>
          <Link
            href="https://www.aetoscg.com/en-au/risk-warning.html"
            color="inherit"
            underline="none"
            target="_blank"
          >
            Risk Warning 
          </Link>
          |{" "}
          <Link
            href="https://www.aetoscg.com/pdf/en-au/aetos_privacy_policy_en.pdf"
            color="inherit"
            underline="none"
            target="_blank"
          >
             Privacy Policy 
          </Link>
          |
          <Link
            href="https://www.aetoscg.com/en-au/disclaimer.html"
            color="inherit"
            underline="none"
            target="_blank"
          >
             Disclaimer 
          </Link>
          |
          <Link
            href="https://www.aetoscg.com/pdf/en-au/fsg_en.pdf"
            color="inherit"
            underline="none"
            target="_blank"
          >
             Financial Sevices Guide 
          </Link>
          |
          <Link
            href="https://www.aetoscg.com/en-au/sitemap.html"
            color="inherit"
            underline="none"
            target="_blank"
          >
             Sitemap
          </Link>
        </TextWrapper>

        <TextWrapper>
          AETOS Capital Group Pty. Ltd. (ACN: 125 113 117; AFSL No.: 313016)
        </TextWrapper>
        <TextWrapper>
          COPYRIGHT © 2023 AETOS Capital Group. All Rights Reserved.
        </TextWrapper>
      </CopyrightWrapper>
    </>
  );
}
