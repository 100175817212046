import { styled } from "@mui/material";

const SectionWrapper = styled("div")((props) => ({
  position: "relative",
  backgroundImage: `url(${props.url})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
}));

const ImageWrapper = styled("div")((props) => ({
  paddingLeft: 80,
  maxWidth: 600,
  height: 50,
  [props.theme.breakpoints.down(500)]: {
    maxWidth: 500,
    height: 45,
    paddingLeft: 60,
  },
  [props.theme.breakpoints.down(450)]: {
    maxWidth: 500,
    height: 40,
    paddingLeft: 60,
  },
  [props.theme.breakpoints.down(400)]: {
    maxWidth: 450,
    height: 35,
    paddingLeft: 55,
  },
}));

export default function MobileSectionHeader({ bkUrl, imgUrl }) {
  return (
    <SectionWrapper url={bkUrl}>
      <ImageWrapper>
        <img src={imgUrl} alt="Section1" height="100%" />
      </ImageWrapper>
    </SectionWrapper>
  );
}
