import React from "react";
import { Routes, Route } from "react-router-dom";
import YouTubeLP from "./pages/YouTube";
import Brochure from "./pages/Brochure";

export const routes = (
  <Routes>
    <Route path="/" element={<YouTubeLP />} />
    <Route path="/brochure" element={<Brochure />} />
  </Routes>
);
