import { styled } from '@mui/material';
import React from 'react';

const Root= styled("div")((props) => ({
  width: "100vw",
  height: "100vh",
  display:"flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#222",
  "&>iframe": {
    // position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    border: "none", 
    padding: 20,
    margin: 0
    }
}));

const Brochure = () => {

  return (
    <Root>
      <iframe 
        title="Aetos"
        loading="lazy" 
        src={process.env.REACT_APP_VIDEO_LINK}
        allowFullScreen="allowfullscreen" 
        allow="fullscreen"
      />
    </Root>
  )
};

export default Brochure;
