import { Typography, styled } from "@mui/material";
import { StyledDivider } from "../../StyledDivider/StyledDivider";

const ImageWrapper = styled("div")((props) => ({
  width: 50,
  marginRight: 20,
  [props.theme.breakpoints.down("sm")]: {
    width: 30,
    marginRight: 10,
  },
}));

const SectionWrapper = styled("div")((props) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "350px auto",
  [props.theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "200px auto",
  },
}));

const TitleWrapper = styled("div")((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: 25,
  backgroundColor: "rgb(2, 2, 2)",
  borderRadius: "0 0 40px 0",
  width: 350,
  height: 90,
  [props.theme.breakpoints.down("sm")]: {
    width: 200,
    height: 50,
    paddingLeft: 15,
    borderRadius: "0 0 20px 0",
  },
}));

const TitleTextWrapper = styled(Typography)((props) => ({
  fontFamily: "SourceHanSerifCN",
  fontSize: 42,
  fontWeight: 700,
  color: "rgb(255, 255, 255)",
  [props.theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

const TopImageWrapper = styled("div")((props) => ({
  width: "100%",
  maxHeight: 70,
}));

const SubtitleWrapper = styled(Typography)((props) => ({
  marginLeft: 40,
  fontSize: 26,
  color: "rgb(255, 255, 255)",
  lineHeight: 1.6,
  [props.theme.breakpoints.down("sm")]: {
    marginLeft: 15,
    fontSize: 14,
  },
}));

export default function CarouselHeader() {
  return (
    <>
      <SectionWrapper>
        <TitleWrapper>
          <ImageWrapper>
            <img src="/images/small/speaker.png" alt="Glenn Yin" width="100%" />
          </ImageWrapper>
          <TitleTextWrapper>Glenn Yin</TitleTextWrapper>
        </TitleWrapper>
        <TopImageWrapper sx={{ display: { sm: "block", xs: "none" } }}>
          <img
            src="/images/small/top_divider.png"
            alt="Glenn Yin"
            width="100%"
          />
        </TopImageWrapper>
        <TopImageWrapper sx={{ display: { sm: "none", xs: "block" } }}>
          <img
            src="/images/mobileBig/top_divider.png"
            alt="Glenn Yin"
            width="100%"
          />
        </TopImageWrapper>
      </SectionWrapper>
      <StyledDivider height={20} mHeight={15} />
      <SubtitleWrapper>
        Glenn Yin定期接受多家国际知名新闻机构采访，
      </SubtitleWrapper>
      <SubtitleWrapper>为全球投资者提供深入的市场分析和评论</SubtitleWrapper>
    </>
  );
}
