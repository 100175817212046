import { styled } from "@mui/material";
import CarouselHeader from "./CarouselHeader";
import CarouselBody from "./CarouselBody";

const RootWrapper = styled("div")((props) => ({
  maxWidth: 820,
  display: "flex",
  margin: "auto",
  flexDirection: "column",
  backgroundColor: "#da2027",
  paddingBottom: 30,
  [props.theme.breakpoints.down("sm")]: {
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 10,
  },
}));

export default function CarouselSection() {
  return (
    <RootWrapper>
      <CarouselHeader />
      <CarouselBody />
    </RootWrapper>
  );
}
