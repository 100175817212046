import { styled } from "@mui/material";
import RegisterForm from "./RegisterForm";

const Root = styled("div")((props) => ({
  width: 420,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [props.theme.breakpoints.down("lg")]: {
    width: "100%"
  }
}))

const FormCard = styled("div")((props) => ({
  width: 420,
  height: 524,
  background: "#fff",
  borderRadius: "10px 10px 0 0",
  [props.theme.breakpoints.down(600)]: {
    maxWidth: "100%",
    height: 450,
  }
}));

const RightCard = ({ uid }) => {
  return (
    <Root>
      <FormCard>
        <RegisterForm uid={uid} />
      </FormCard>
    </Root>
  )
}

export default RightCard