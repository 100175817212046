import Note from "./Note";
import Copyright from "./Copyright";

export default function Footer() {
  return (
    <>
      <Note />
      <Copyright />
    </>
  );
}
