import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { StyledDivider } from "../StyledDivider/StyledDivider";
import itemIcon from "../../assets/images/item_icon.png";
import arrow from "../../assets/images/arrow295.png";
import SectionHeader from "./components/SectionHeader";
import MobileSectionHeader from "./components/MobileSectionHeader";

const SectionWrapper = styled("div")((props) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  columnGap: 10,
}));

const ImageWrapper = styled("div")((props) => ({
  height: "100%",
}));

const SectionContainer = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  maxWidth: 820,
  [props.theme.breakpoints.down(1200)]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const RowWrapper = styled("div")((props) => ({
  display: "flex",
  alignItems: "flex-start",
  width: "fit-content",
  border: "solid 2px #000",
  padding: "15px 30px 15px 15px",
  marginBottom: 20,
  "&>img": {
    paddingTop: 4,
  },
  [props.theme.breakpoints.down(768)]: {
    marginBottom: 15,
    "&>img": {
      width: 20,
      paddingTop: 2,
    },
  },
  [props.theme.breakpoints.down(600)]: {
    padding: "10px 5px",
    marginBottom: 10,
    "&>img": {
      width: 15,
      paddingTop: 0,
    },
  },
}));

const TextWrapper = styled(Typography)((props) => ({
  marginLeft: 10,
  fontSize: 26,
  fontWeight: 700,
  color: "rgb(0, 0, 0)",
  lineHeight: 1.2,
  [props.theme.breakpoints.down(960)]: {
    fontSize: 22,
  },
  [props.theme.breakpoints.down(768)]: {
    fontSize: 18,
  },
  [props.theme.breakpoints.down(600)]: {
    marginLeft: 5,
    fontSize: 12,
    fontWeight: 400,
  },
}));

const showTextRow = (value) => {
  return (
    <>
      <RowWrapper>
        <img src={itemIcon} alt="" width={25} />
        <TextWrapper>{value}</TextWrapper>
      </RowWrapper>
    </>
  );
};

export default function Seamless() {
  return (
    <>
      <Box sx={{ display: { sm: "block", xs: "none" } }}>
        <SectionHeader
          bkUrl="/images/small/divider1.png"
          imgUrl="/images/small/wufeng.png"
        />
      </Box>
      <Box sx={{ display: { sm: "none", xs: "block" } }}>
        <MobileSectionHeader
          bkUrl="/images/mobileBig/divider1.png"
          imgUrl="/images/mobileBig/wufeng.png"
        />
      </Box>

      <StyledDivider height={30} />
      <SectionContainer>
        <img
          src="/images/small/seamless_banner.png"
          alt="Seamless Banner"
          width="100%"
        />
        <StyledDivider height={30} mHeight={15} />
        <SectionWrapper>
          <ImageWrapper>
            <img src={arrow} alt="" height="95%" />
          </ImageWrapper>
          <div>
            {showTextRow("来自我们全球市场分析师的专业分析和见解")}
            {showTextRow(
              "仅1小时掌握全球当月经济事件，找到适合您目标的多样化投资组合"
            )}
            {showTextRow(
              "分享基于经过 AETOS 艾拓思投研部验证的可信交易策略，更好地管理风险"
            )}
            {showTextRow("实盘演示当月重点关注产品的最火交易机会")}
            {showTextRow(
              "访问强大的交易工具，学习利用技术指标、经济新闻和市场分析"
            )}
            {showTextRow(
              "在我们全球领先的平台上无缝交易六大金融衍生品，包括外汇、金属、能源、指数、环球股票、ETFs等"
            )}
          </div>
        </SectionWrapper>
      </SectionContainer>
      <StyledDivider height={80} mHeight={40} />
    </>
  );
}
