import { styled } from "@mui/material";
import Slider from "react-slick";
import { isPopulatedArray } from "../../../utils";

const SectionWrapper = styled("div")((props) => ({
  width: "100%",
  paddingLeft: 45,
  [props.theme.breakpoints.down("sm")]: {
    paddingLeft: 25,
  },
}));

const SliderWrapper = styled("div")((props) => ({
  height: 400,
  display: "flex",
  margin: "auto",
  paddingRight: 40,
  [props.theme.breakpoints.down("sm")]: {
    height: 300,
  },
}));

const PreviousImageWrapper = styled("img")((props) => ({
  width: "50px",
  height: "50px",
  position: "absolute",
  zIndex: 38,
  left: 0,
  top: "45%",
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const NextImageWrapper = styled("img")((props) => ({
  width: "50px",
  height: "50px",
  position: "absolute",
  zIndex: 38,
  right: 45,
  top: "45%",
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const ArrowButtonPrevious = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: "transparent", border: "none" }}
    >
      <PreviousImageWrapper src="/images/small/left_arrow.png" alt="<" />
    </button>
  );
};

const ArrowButtonNext = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: "transparent", borderRadius: 50, border: 0 }}
    >
      <NextImageWrapper src="/images/small/next_arrow.png" alt=">" />
    </button>
  );
};

const sliderImages = [
  { src: "/images/small/slider1.jpg", alt: "Slider1" },
  { src: "/images/small/slider2.jpg", alt: "Slider2" },
  { src: "/images/small/slider3.jpg", alt: "Slider3" },
  { src: "/images/small/slider4.jpg", alt: "Slider4" },
  { src: "/images/small/slider5.jpg", alt: "Slider5" },
  { src: "/images/small/slider6.jpg", alt: "Slider6" },
  { src: "/images/small/slider7.jpg", alt: "Slider7" },
  { src: "/images/small/slider8.jpg", alt: "Slider8" },
];
export default function CarouselBody() {
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    speed: 5000,
    cssEase: "linear",
    variableWidth: true,
    dots: false,
    slidesToScroll: 1,
    arrows: true,
    pauseOnHover: true,
    prevArrow: <ArrowButtonPrevious />,
    nextArrow: <ArrowButtonNext />,
  };
  return (
    <SectionWrapper>
      <Slider {...settings}>
        {isPopulatedArray(sliderImages) &&
          sliderImages.map((item, index) => (
            <SliderWrapper key={index}>
              <img src={item.src} alt={item.alt} height="100%" />
            </SliderWrapper>
          ))}
      </Slider>
    </SectionWrapper>
  );
}
