import { Typography, styled } from "@mui/material";
import { StyledDivider } from "../StyledDivider/StyledDivider";

const NoteLayoutWrapper = styled("div")((props) => ({
  backgroundColor: "#1a1a1a",
  color: "#ffffff",
  paddingTop: 30,
  paddingBottom: 30,
  "& *": { fontFamily: "Georgia !important" },
}));

const NoteWrapper = styled("div")((props) => ({
  maxWidth: 1100,
  margin: "auto",
  [props.theme.breakpoints.down(1100)]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  [props.theme.breakpoints.down(600)]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const NoteItemWrapper = styled(Typography)((props) => ({
  fontSize: 14,
  lineHeight: 2,
  [props.theme.breakpoints.down(600)]: {
    fontSize: 12,
    lineHeight: 1.5,
  },
}));

export default function Note() {
  return (
    <>
      <NoteLayoutWrapper>
        <NoteWrapper>
          <NoteItemWrapper>Important note</NoteItemWrapper>
          <NoteItemWrapper>
            The information contained in this website is of general nature only
            and does not take into account your objectives, financial situation
            or needs. You are strongly recommended to seek independent financial
            advice before making any investment decisions.
          </NoteItemWrapper>
          <StyledDivider height={15} mHeight={10} />
          <NoteItemWrapper>
            Trading margin FX and CFDs carries a high level of risk and may not
            be suitable for all investors. AETOS products are more suitable for
            investors with high investment risk tolerance, you may refer to
            the Target Market Determination (TMD) , which is available on our
            website, for more information about attributes of AETOS target
            market of customers. AETOS clients are categorised as retail clients
            by default unless you satisfy wholesale clients criteria. Retails
            clients could sustain a total loss of deposited funds but are not
            subject to subsequent payment obligations beyond the deposited funds
            but wholesale clients could sustain losses more than your initial
            investment. Please ensure that you read the Financial Services Guide
            (FSG) , Product Disclosure Statement (PDS) , and Terms and
            Conditions which can be obtained on this website, and fully
            understand the risks involved before deciding to acquire any of the
            financial products listed on this website.
          </NoteItemWrapper>
          <StyledDivider height={15} mHeight={10} />
          <NoteItemWrapper>
            AETOS Capital Group Pty Ltd is registered in Australia (ACN 125 113
            117; AFSL No. 313016) since 2007 and is a wholly owned subsidiary of
            AETOS Capital Group Holdings Limited, carrying on a financial
            services business in Australia, limited to providing the financial
            services covered by the Australian financial services licence.
          </NoteItemWrapper>
          <StyledDivider height={15} mHeight={10} />
          <NoteItemWrapper>
            This website is owned and prepared by AETOS Capital Group Pty Ltd.
            It is compiled and presented in accordance with Australian laws and
            regulations. The products on the website are issued by AETOS.
          </NoteItemWrapper>
          <StyledDivider height={15} mHeight={10} />
          <NoteItemWrapper>
            AETOS solicits business in Australia, and the products and services
            described on this website may not be available in all countries and
            jurisdictions.
          </NoteItemWrapper>
        </NoteWrapper>
      </NoteLayoutWrapper>
    </>
  );
}
