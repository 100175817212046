import { styled, useMediaQuery } from "@mui/material";
import LeftContent from "./LeftContent";
import RightCard from "./RightCard";

const BannerRoot = styled("div")((props) => ({
  width: "100%",
}));

const BannerBg = styled("div")((props) => ({
  position: "relative",
  width: "100%",
  height: "auto",
  minHeight: 642,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&>img": {
    width: "100%",
    height: "100%",
    minHeight: 642,
  },
  [props.theme.breakpoints.down("lg")]: {
    "&>img": {
      maxHeight: 1000,
    },
  },
  [props.theme.breakpoints.down(768)]: {
    "&>img": {
      minHeight: 830,
    }
  },
  [props.theme.breakpoints.down(600)]: {
    "&>img": {
      height: 830,
    },
  },
}));

const BannerWrapper = styled("div")((props) => ({
  position: "absolute",
  paddingTop: 80,
  paddingBottom: 30,
  maxWidth: 1100,
  width: "100%",
  // minHeight: 630,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // [props.theme.breakpoints.down(1720)]: {
  //   paddingLeft: 20,
  //   paddingRight: 20,
  // },
  [props.theme.breakpoints.down("lg")]: {
    paddingTop: 50,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  [props.theme.breakpoints.down(768)]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const Banner = ({ uid }) => {
  const transferSize = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <BannerRoot>
      <BannerBg>
        <img
          src={
            transferSize ?
              "/images/mobileBig/banner_bg.jpg"
              :
              "/images/big/banner_new.jpg"}
          alt=""
        />
        <BannerWrapper>
          <LeftContent />
          <RightCard uid={uid} />
        </BannerWrapper>
      </BannerBg>
    </BannerRoot>
  )
};

export default Banner;