import { Container, styled } from "@mui/material";
import Seamless from "./Seamless";
import Experienced from "./Experienced";
import Limited from "./Limited";
import { StyledDivider } from "../StyledDivider/StyledDivider";
import SectionBanner from "./components/SectionBanner";

const RootWrapper = styled("div")((props) => ({
  position: "relative",
  background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.6)),url("/images/small/bg.jpg")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

export default function MainContent() {
  return (
    <>
      <SectionBanner />
      <Container maxWidth="xl" disableGutters={true}>
        <RootWrapper>
          <StyledDivider height={150} mHeight={70} />
          <Seamless />
          <Experienced />
          <Limited />
        </RootWrapper>
      </Container>
    </>
  );
}
